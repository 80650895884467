import * as actions from './actions';
import * as checkoutActions from '../checkout/actions';

const updateCart = (productNumber, qty, index = false, updateCartCount) => {
  return function (dispatch, getState) {
    dispatch(actions.updateCartAjax(productNumber, qty, index)).then(() => {
      const state = getState();
      updateCartCount(state.getIn(['cart', 'product_count', 'count']));
    });
  }
}

const removeItem = (productNumber, product, index, updateCartCount) => {
  return function (dispatch, getState) {
    dispatch(actions.removeItemAjax(productNumber, product, index)).then(() => {
      const state = getState();
      updateCartCount(state.getIn(['cart', 'product_count', 'count']));
    });
  }
}

export function goToCheckout(value, changePage) {
  return (dispatch) => {
    dispatch(actions.setAdminCheckout(value)).then(() => {
      if (value) {
        changePage('/checkout/account-search');
      } else {
        changePage('/checkout/shipping');
      }
    })
  }
}

const addToCartAndRedirect = (number, qty, changePage, redirect = '/cart') => {
  return function(dispatch) {
    dispatch(actions.addToCart(number, qty)).then(() =>{
      changePage(redirect);
    });
  }
}

const populateQuote = (id, changePage) => {
  return function(dispatch) {
    dispatch(actions.getQuote(id)).then(() => {
      changePage('/cart');
    });
  }
}

const saveQuote = (values = null, page = null, quoteDetails = null) => {
  return function(dispatch, getState) {
    switch (page) {
      case 'shipping':
        dispatch(checkoutActions.getShippingAjax(values, true)).then(() => {saveQuoteFunction(quoteDetails)});
        break;
      case 'billing':
        dispatch(checkoutActions.submitBillingAjax(values)).then(() => {saveQuoteFunction(quoteDetails)});
        break;
      default:
        saveQuoteFunction(values);
        break;
    }

    function saveQuoteFunction(values = null) {
      dispatch(actions.saveQuoteAjax(values)).then(() => {
        const state = getState();
        const name = values ? values.purchase_ship_contact : state.getIn(['cart', 'pending', 'purchase_pending_saved_shipping', 'name']);
        window.location.href = '/admin_checkout.php?action=savedordersearch&purchase_ship_contact=' + name;
      });
    }
  }
}

const alertAction = (url, data = {}, method = 'GET', cb = null) => {
  return function (dispatch, getState) {
    dispatch(actions.alertActionAjax(url, data, method)).then(() => {
      if (cb) {
        cb();
      }
    });
  }
}

const getCart = (admin = false, cb = null) => {
  return function (dispatch) {
    dispatch(actions.getCartAjax(admin)).then(() => {
      if (cb) {
        cb();
      }
    });
  }
}

const rewriteCart = (newCart, options = {}, cb = null) => {
  return function (dispatch) {
    dispatch(actions.rewriteCartAjax(newCart, options)).then(() => {
      if (cb) {
        cb();
      }
    });
  }
}

export default {
  ...actions,
  updateCart,
  removeItem,
  addToCartAndRedirect,
  goToCheckout,
  populateQuote,
  saveQuote,
  alertAction,
  getCart,
  rewriteCart,
};

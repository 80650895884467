import React from 'react';
import Banner from '../Banner';
import { useAjax } from '@developers/use-ajax';
import * as bannerAjax from '../../ajax/banner';

interface Banner {
  id: number;
  message: string;
}

function AutoBanner() {
  const [banners, getBanners] = useAjax(bannerAjax.getActiveBanners, [] as Banner[]);

  React.useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <>
      {banners.map((banner) => (
        <Banner key={banner.id} text={banner.message} />
      ))}
    </>
  );
}

export default AutoBanner;
import ajax from '@developers/utils/ajax';

/**
 * If an action contains a key called `ajax`, this middleware will make an ajax request using the values inside.
 * 
 */
const ajaxMiddleware = (store) => (next) => (action) => {
  const { types, props, ...rest } = action;

  if (!action.ajax) {
    return next(action);
  }

  const [REQUEST, SUCCESS, FAILURE] = types;

  next({...rest, type: REQUEST});

  return new Promise(resolve => {
    ajax({...action.ajax}).then(r => {
      if (!r.ok) {
        try {
          const failedResp = Promise.resolve(r.json());
          failedResp.then(payload => {
            next({...rest, status: r.status, ok: false, payload, type: FAILURE});
            resolve();
          });
        } catch (error) {
          next({...rest, ok: false, error, type: FAILURE});
          resolve();
        }
      } else {
        try {
          r.json().then(payload => {
            next({...rest, status: r.status, ok: r.ok, payload, type: SUCCESS});
            resolve();
          });
        } catch (error) {
          next({...rest, ok: false, error, type: FAILURE});
          resolve();
        }
      }
    }).catch(error => {
      next({...rest, ok: false, error, type: FAILURE});
      resolve();
    });
  });
};

export default ajaxMiddleware;

import addNamespace from '../addNamespace';

const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';

const GET_USER_WISHLIST_REQUEST = 'GET_USER_WISHLIST_REQUEST';
const GET_USER_WISHLIST_SUCCESS = 'GET_USER_WISHLIST_SUCCESS';
const GET_USER_WISHLIST_FAILURE = 'GET_USER_WISHLIST_FAILURE';

const GET_ALL_WISHLISTS_REQUEST = 'GET_ALL_WISHLISTS_REQUEST';
const GET_ALL_WISHLISTS_SUCCESS = 'GET_ALL_WISHLISTS_SUCCESS';
const GET_ALL_WISHLISTS_FAILURE = 'GET_ALL_WISHLISTS_FAILURE';

const UPDATE_WISHLIST_STATUS_REQUEST = 'UPDATE_WISHLIST_STATUS_REQUEST';
const UPDATE_WISHLIST_STATUS_SUCCESS = 'UPDATE_WISHLIST_STATUS_SUCCESS';
const UPDATE_WISHLIST_STATUS_FAILURE = 'UPDATE_WISHLIST_STATUS_FAILURE';

const DELETE_WISHLIST_REQUEST = 'DELETE_WISHLIST_REQUEST';
const DELETE_WISHLIST_SUCCESS = 'DELETE_WISHLIST_SUCCESS';
const DELETE_WISHLIST_FAILURE = 'DELETE_WISHLIST_FAILURE';

const CREATE_UPDATE_SUGGESTION_REQUEST = 'CREATE_UPDATE_SUGGESTION_REQUEST';
const CREATE_UPDATE_SUGGESTION_SUCCESS = 'CREATE_UPDATE_SUGGESTION_SUCCESS';
const CREATE_UPDATE_SUGGESTION_FAILURE = 'CREATE_UPDATE_SUGGESTION_FAILURE';

const GET_MANUFACTURERS_REQUEST = 'GET_MANUFACTURERS_REQUEST';
const GET_MANUFACTURERS_SUCCESS = 'GET_MANUFACTURERS_SUCCESS';
const GET_MANUFACTURERS_FAILURE = 'GET_MANUFACTURERS_FAILURE';

export default addNamespace({
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_USER_WISHLIST_REQUEST,
  GET_USER_WISHLIST_SUCCESS,
  GET_USER_WISHLIST_FAILURE,
  GET_ALL_WISHLISTS_REQUEST,
  GET_ALL_WISHLISTS_SUCCESS,
  GET_ALL_WISHLISTS_FAILURE,
  UPDATE_WISHLIST_STATUS_REQUEST,
  UPDATE_WISHLIST_STATUS_SUCCESS,
  UPDATE_WISHLIST_STATUS_FAILURE,
  DELETE_WISHLIST_REQUEST,
  DELETE_WISHLIST_SUCCESS,
  DELETE_WISHLIST_FAILURE,
  CREATE_UPDATE_SUGGESTION_REQUEST,
  CREATE_UPDATE_SUGGESTION_SUCCESS,
  CREATE_UPDATE_SUGGESTION_FAILURE,
  GET_MANUFACTURERS_REQUEST,
  GET_MANUFACTURERS_SUCCESS,
  GET_MANUFACTURERS_FAILURE
}, 'products');

import addNamespace from '../addNamespace';

const GET_CART_REQUEST = 'GET_CART_REQUEST';
const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
const GET_CART_FAILURE = 'GET_CART_FAILURE';
const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';
const REMOVE_ITEM_REQUEST = 'REMOVE_ITEM_REQUEST';
const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCESS';
const REMOVE_ITEM_FAILURE = 'REMOVE_ITEM_FAILURE';
const UPDATE_ZIP_REQUEST = 'UPDATE_ZIP_REQUEST';
const UPDATE_ZIP_SUCCESS = 'UPDATE_ZIP_SUCCESS';
const UPDATE_ZIP_FAILURE = 'UPDATE_ZIP_FAILURE';
const UPDATE_SHIPPING_REQUEST = 'UPDATE_SHIPPING_REQUEST';
const UPDATE_SHIPPING_SUCCESS = 'UPDATE_SHIPPING_SUCCESS';
const UPDATE_SHIPPING_FAILURE = 'UPDATE_SHIPPING_FAILURE';
const CLEAR_REMOVED_PRODUCTS = 'CLEAR_REMOVED_PRODUCTS';
const GET_CART_COUNT_REQUEST = 'GET_CART_COUNT_REQUEST';
const GET_CART_COUNT_SUCCESS = 'GET_CART_COUNT_SUCCESS';
const GET_CART_COUNT_FAILURE = 'GET_CART_COUNT_FAILURE';
const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT';
const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST';
const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
const RESET_CART = 'RESET_CART';
const GET_COUPON_REQUEST = 'GET_COUPON_REQUEST';
const GET_COUPON_SUCCESS = 'GET_COUPON_SUCCESS';
const GET_COUPON_FAILURE = 'GET_COUPON_FAILURE';
const REMOVE_COUPON_REQUEST = 'REMOVE_COUPON_REQUEST';
const REMOVE_COUPON_SUCCESS = 'REMOVE_COUPON_SUCCESS';
const REMOVE_COUPON_FAILURE = 'REMOVE_COUPON_FAILURE';
const SET_ADMIN_CHECKOUT_REQUEST = 'SET_ADMIN_CHECKOUT_REQUEST';
const SET_ADMIN_CHECKOUT_SUCCESS = 'SET_ADMIN_CHECKOUT_SUCCESS';
const SET_ADMIN_CHECKOUT_FAILURE = 'SET_ADMIN_CHECKOUT_FAILURE';
const CLEAR_ALERTS = 'CLEAR_ALERTS';
const SAVE_QUOTE_REQUEST = 'SAVE_QUOTE_REQUEST';
const SAVE_QUOTE_SUCCESS = 'SAVE_QUOTE_SUCCESS';
const SAVE_QUOTE_FAILURE = 'SAVE_QUOTE_FAILURE';
const POPULATE_QUOTE_REQUEST = 'POPULATE_QUOTE_REQUEST';
const POPULATE_QUOTE_SUCCESS = 'POPULATE_QUOTE_SUCCESS';
const POPULATE_QUOTE_FAILURE = 'POPULATE_QUOTE_FAILURE';
const REWRITE_CART_REQUEST = 'REWRITE_CART_REQUEST';
const REWRITE_CART_SUCCESS = 'REWRITE_CART_SUCCESS';
const REWRITE_CART_FAILURE = 'REWRITE_CART_FAILURE';
const CLEAR_WARRANTY = 'CLEAR_WARRANTY';
const ALERT_ACTION_REQUEST = 'ALERT_ACTION_REQUEST';
const ALERT_ACTION_SUCCESS = 'ALERT_ACTION_SUCCESS';
const ALERT_ACTION_FAILURE = 'ALERT_ACTION_FAILURE';
const REQUIRE_SIGNATURE_REQUEST = 'REQUIRE_SIGNATURE_REQUEST';
const REQUIRE_SIGNATURE_SUCCESS = 'REQUIRE_SIGNATURE_SUCCESS';
const REQUIRE_SIGNATURE_FAILURE = 'REQUIRE_SIGNATURE_FAILURE';

export default addNamespace({
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  REMOVE_ITEM_REQUEST,
  REMOVE_ITEM_SUCCESS,
  REMOVE_ITEM_FAILURE,
  UPDATE_ZIP_REQUEST,
  UPDATE_ZIP_SUCCESS,
  UPDATE_ZIP_FAILURE,
  UPDATE_SHIPPING_REQUEST,
  UPDATE_SHIPPING_SUCCESS,
  UPDATE_SHIPPING_FAILURE,
  CLEAR_REMOVED_PRODUCTS,
  GET_CART_COUNT_REQUEST,
  GET_CART_COUNT_SUCCESS,
  GET_CART_COUNT_FAILURE,
  UPDATE_CART_COUNT,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  RESET_CART,
  GET_COUPON_REQUEST,
  GET_COUPON_SUCCESS,
  GET_COUPON_FAILURE,
  REMOVE_COUPON_REQUEST,
  REMOVE_COUPON_SUCCESS,
  REMOVE_COUPON_FAILURE,
  SET_ADMIN_CHECKOUT_REQUEST,
  SET_ADMIN_CHECKOUT_SUCCESS,
  SET_ADMIN_CHECKOUT_FAILURE,
  CLEAR_ALERTS,
  SAVE_QUOTE_FAILURE,
  SAVE_QUOTE_SUCCESS,
  SAVE_QUOTE_REQUEST,
  POPULATE_QUOTE_REQUEST,
  POPULATE_QUOTE_SUCCESS,
  POPULATE_QUOTE_FAILURE,
  REWRITE_CART_REQUEST,
  REWRITE_CART_SUCCESS,
  REWRITE_CART_FAILURE,
  CLEAR_WARRANTY,
  ALERT_ACTION_REQUEST,
  ALERT_ACTION_SUCCESS,
  ALERT_ACTION_FAILURE,
  REQUIRE_SIGNATURE_REQUEST,
  REQUIRE_SIGNATURE_SUCCESS,
  REQUIRE_SIGNATURE_FAILURE,
}, 'cart');

import addNamespace from '../addNamespace';

const GET_WARRANTY_PARTS_REQUEST = 'GET_WARRANTY_PARTS_REQUEST';
const GET_WARRANTY_PARTS_SUCCESS = 'GET_WARRANTY_PARTS_SUCCESS';
const GET_WARRANTY_PARTS_FAILURE = 'GET_WARRANTY_PARTS_FAILURE';
const GET_VENDORS_REQUEST = 'GET_VENDORS_REQUEST';
const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';
const GET_VENDORS_FAILURE = 'GET_VENDORS_FAILURE';
const GET_FULL_PRICE_PARTS_REQUEST = 'GET_FULL_PRICE_PARTS_REQUEST';
const GET_FULL_PRICE_PARTS_SUCCESS = 'GET_FULL_PRICE_PARTS_SUCCESS';
const GET_FULL_PRICE_PARTS_FAILURE = 'GET_FULL_PRICE_PARTS_FAILURE';
const GET_ORDER_SURCHARGES_REQUEST = 'GET_ORDER_SURCHARGES_REQUEST';
const GET_ORDER_SURCHARGES_SUCCESS = 'GET_ORDER_SURCHARGES_SUCCESS';
const GET_ORDER_SURCHARGES_FAILURE = 'GET_ORDER_SURCHARGES_FAILURE';
const GET_LABOR_REIMBURSEMENTS_REQUEST = 'GET_LABOR_REIMBURSEMENTS_REQUEST';
const GET_LABOR_REIMBURSEMENTS_SUCCESS = 'GET_LABOR_REIMBURSEMENTS_SUCCESS';
const GET_LABOR_REIMBURSEMENTS_FAILURE = 'GET_LABOR_REIMBURSEMENTS_FAILURE';
const UPDATE_LABOR_REIMBURSEMENT_REQUEST = 'UPDATE_LABOR_REIMBURSEMENT_REQUEST';
const UPDATE_LABOR_REIMBURSEMENT_SUCCESS = 'UPDATE_LABOR_REIMBURSEMENT_SUCCESS';
const UPDATE_LABOR_REIMBURSEMENT_FAILURE = 'UPDATE_LABOR_REIMBURSEMENT_FAILURE';
const GET_PRODUCT_COUNTS_REQUEST = 'GET_PRODUCT_COUNTS_REQUEST';
const GET_PRODUCT_COUNTS_SUCCESS = 'GET_PRODUCT_COUNTS_SUCCESS';
const GET_PRODUCT_COUNTS_FAILURE = 'GET_PRODUCT_COUNTS_FAILURE';
const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';
const GET_TERM_REQUEST = 'GET_TERM_REQUEST';
const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS';
const GET_TERM_FAILURE = 'GET_TERM_FAILURE';
const SAVE_TERM_REQUEST = 'SAVE_TERM_REQUEST';
const SAVE_TERM_SUCCESS = 'SAVE_TERM_SUCCESS';
const SAVE_TERM_FAILURE = 'SAVE_TERM_FAILURE';
const FIND_TERM_REQUEST = 'FIND_TERM_REQUEST';
const FIND_TERM_SUCCESS = 'FIND_TERM_SUCCESS';
const FIND_TERM_FAILURE = 'FIND_TERM_FAILURE';
const UPDATE_TERM_REQUEST = 'UPDATE_TERM_REQUEST';
const UPDATE_TERM_SUCCESS = 'UPDATE_TERM_SUCCESS';
const UPDATE_TERM_FAILURE = 'UPDATE_TERM_FAILURE';
const DELETE_TERM_REQUEST = 'DELETE_TERM_REQUEST';
const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
const DELETE_TERM_FAILURE = 'DELETE_TERM_FAILURE';
const SAVE_SYNONYM_REQUEST = 'SAVE_SYNONYM_REQUEST';
const SAVE_SYNONYM_SUCCESS = 'SAVE_SYNONYM_SUCCESS';
const SAVE_SYNONYM_FAILURE = 'SAVE_SYNONYM_FAILURE';
const DETACH_SYNONYM_REQUEST = 'DETACH_SYNONYM_REQUEST';
const DETACH_SYNONYM_SUCCESS = 'DETACH_SYNONYM_SUCCESS';
const DETACH_SYNONYM_FAILURE = 'DETACH_SYNONYM_FAILURE';
const RESET_TERM = 'RESET_TERM';
const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
const GET_SIMILAR_ACCOUNTS_REQUEST = 'GET_SIMILAR_ACCOUNTS_REQUEST';
const GET_SIMILAR_ACCOUNTS_SUCCESS = 'GET_SIMILAR_ACCOUNTS_SUCCESS';
const GET_SIMILAR_ACCOUNTS_FAILURE = 'GET_SIMILAR_ACCOUNTS_FAILURE';
const CLEAR_SIMILAR_ACCOUNTS = 'CLEAR_SIMILAR_ACCOUNTS';
const MERGE_ACCOUNTS_REQUEST = 'MERGE_ACCOUNTS_REQUEST';
const MERGE_ACCOUNTS_SUCCESS = 'MERGE_ACCOUNTS_SUCCESS';
const MERGE_ACCOUNTS_FAILURE = 'MERGE_ACCOUNTS_FAILURE';
const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';
const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT';
const GET_ORDERS_BY_STATE_REQUEST = 'GET_ORDERS_BY_STATE_REQUEST';
const GET_ORDERS_BY_STATE_SUCCESS = 'GET_ORDERS_BY_STATE_SUCCESS';
const GET_ORDERS_BY_STATE_FAILURE = 'GET_ORDERS_BY_STATE_FAILURE';
const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
const GET_STATES_FAILURE = 'GET_STATES_FAILURE';
const GET_EXPANDED_SHIPPING_RATES_REQUEST = 'GET_EXPANDED_SHIPPING_RATES_REQUEST';
const GET_EXPANDED_SHIPPING_RATES_SUCCESS = 'GET_EXPANDED_SHIPPING_RATES_SUCCESS';
const GET_EXPANDED_SHIPPING_RATES_FAILURE = 'GET_EXPANDED_SHIPPING_RATES_FAILURE';
const CLEAR_EXPANDED_SHIPPING_RATES = 'CLEAR_EXPANDED_SHIPPING_RATES';
const GET_EXTENDED_LABOR_REQUEST = 'GET_EXTENDED_LABOR_REQUEST';
const GET_EXTENDED_LABOR_SUCCESS = 'GET_EXTENDED_LABOR_SUCCESS';
const GET_EXTENDED_LABOR_FAILURE = 'GET_EXTENDED_LABOR_FAILURE';
const GET_WARRANTY_SHIPPING_REQUEST = 'GET_WARRANTY_SHIPPING_REQUEST';
const GET_WARRANTY_SHIPPING_SUCCESS = 'GET_WARRANTY_SHIPPING_SUCCESS';
const GET_WARRANTY_SHIPPING_FAILURE = 'GET_WARRANTY_SHIPPING_FAILURE';
const GET_TOTAL_SALES_REQUEST = 'GET_TOTAL_SALES_REQUEST';
const GET_TOTAL_SALES_SUCCESS = 'GET_TOTAL_SALES_SUCCESS';
const GET_TOTAL_SALES_FAILURE = 'GET_TOTAL_SALES_FAILURE';
const GET_PRODUCTS_WITHOUT_VENDORS_REQUEST = 'GET_PRODUCTS_WITHOUT_VENDORS_REQUEST';
const GET_PRODUCTS_WITHOUT_VENDORS_SUCCESS = 'GET_PRODUCTS_WITHOUT_VENDORS_SUCCESS';
const GET_PRODUCTS_WITHOUT_VENDORS_FAILURE = 'GET_PRODUCTS_WITHOUT_VENDORS_FAILURE';
const GET_SHIPPING_BILL_SUMMARY_REQUEST = 'GET_SHIPPING_BILL_SUMMARY_REQUEST';
const GET_SHIPPING_BILL_SUMMARY_SUCCESS = 'GET_SHIPPING_BILL_SUMMARY_SUCCESS';
const GET_SHIPPING_BILL_SUMMARY_FAILURE = 'GET_SHIPPING_BILL_SUMMARY_FAILURE';
const GET_MARKETPLACE_VENDORS_REQUEST = 'GET_MARKETPLACE_VENDORS_REQUEST';
const GET_MARKETPLACE_VENDORS_SUCCESS = 'GET_MARKETPLACE_VENDORS_SUCCESS';
const GET_MARKETPLACE_VENDORS_FAILURE = 'GET_MARKETPLACE_VENDORS_FAILURE';
const GET_MARKETPLACE_BILLING_SUMMARY_REQUEST = 'GET_MARKETPLACE_BILLING_SUMMARY_REQUEST';
const GET_MARKETPLACE_BILLING_SUMMARY_SUCCESS = 'GET_MARKETPLACE_BILLING_SUMMARY_SUCCESS';
const GET_MARKETPLACE_BILLING_SUMMARY_FAILURE = 'GET_MARKETPLACE_BILLING_SUMMARY_FAILURE';
const GET_FILES_REQUEST = 'GET_FILES_REQUEST';
const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
const GET_FILES_FAILURE = 'GET_FILES_FAILURE';
const GET_FILES_CSV_REQUEST = 'GET_FILES_CSV_REQUEST';
const GET_FILES_CSV_SUCCESS = 'GET_FILES_CSV_SUCCESS';
const GET_FILES_CSV_FAILURE = 'GET_FILES_CSV_FAILURE';

export default addNamespace({
  GET_WARRANTY_PARTS_REQUEST,
  GET_WARRANTY_PARTS_SUCCESS,
  GET_WARRANTY_PARTS_FAILURE,
  GET_FULL_PRICE_PARTS_REQUEST,
  GET_FULL_PRICE_PARTS_SUCCESS,
  GET_FULL_PRICE_PARTS_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  GET_ORDER_SURCHARGES_REQUEST,
  GET_ORDER_SURCHARGES_SUCCESS,
  GET_ORDER_SURCHARGES_FAILURE,
  GET_LABOR_REIMBURSEMENTS_REQUEST,
  GET_LABOR_REIMBURSEMENTS_SUCCESS,
  GET_LABOR_REIMBURSEMENTS_FAILURE,
  UPDATE_LABOR_REIMBURSEMENT_REQUEST,
  UPDATE_LABOR_REIMBURSEMENT_SUCCESS,
  UPDATE_LABOR_REIMBURSEMENT_FAILURE,
  GET_PRODUCT_COUNTS_REQUEST,
  GET_PRODUCT_COUNTS_SUCCESS,
  GET_PRODUCT_COUNTS_FAILURE,
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
  GET_TERM_FAILURE,
  SAVE_TERM_REQUEST,
  SAVE_TERM_SUCCESS,
  SAVE_TERM_FAILURE,
  FIND_TERM_REQUEST,
  FIND_TERM_SUCCESS,
  FIND_TERM_FAILURE,
  UPDATE_TERM_REQUEST,
  UPDATE_TERM_SUCCESS,
  UPDATE_TERM_FAILURE,
  DELETE_TERM_REQUEST,
  DELETE_TERM_SUCCESS,
  DELETE_TERM_FAILURE,
  SAVE_SYNONYM_REQUEST,
  SAVE_SYNONYM_SUCCESS,
  SAVE_SYNONYM_FAILURE,
  DETACH_SYNONYM_REQUEST,
  DETACH_SYNONYM_SUCCESS,
  DETACH_SYNONYM_FAILURE,
  RESET_TERM,
  RESET_NOTIFICATION,
  GET_SIMILAR_ACCOUNTS_REQUEST,
  GET_SIMILAR_ACCOUNTS_SUCCESS,
  GET_SIMILAR_ACCOUNTS_FAILURE,
  CLEAR_SIMILAR_ACCOUNTS,
  MERGE_ACCOUNTS_REQUEST,
  MERGE_ACCOUNTS_SUCCESS,
  MERGE_ACCOUNTS_FAILURE,
  GET_ACCOUNT_REQUEST,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILURE,
  CLEAR_ACCOUNT,
  GET_ORDERS_BY_STATE_REQUEST,
  GET_ORDERS_BY_STATE_SUCCESS,
  GET_ORDERS_BY_STATE_FAILURE,
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
  GET_EXPANDED_SHIPPING_RATES_REQUEST,
  GET_EXPANDED_SHIPPING_RATES_SUCCESS,
  GET_EXPANDED_SHIPPING_RATES_FAILURE,
  CLEAR_EXPANDED_SHIPPING_RATES,
  GET_EXTENDED_LABOR_REQUEST,
  GET_EXTENDED_LABOR_SUCCESS,
  GET_EXTENDED_LABOR_FAILURE,
  GET_WARRANTY_SHIPPING_REQUEST,
  GET_WARRANTY_SHIPPING_SUCCESS,
  GET_WARRANTY_SHIPPING_FAILURE,
  GET_TOTAL_SALES_REQUEST,
  GET_TOTAL_SALES_SUCCESS,
  GET_TOTAL_SALES_FAILURE,
  GET_PRODUCTS_WITHOUT_VENDORS_REQUEST,
  GET_PRODUCTS_WITHOUT_VENDORS_SUCCESS,
  GET_PRODUCTS_WITHOUT_VENDORS_FAILURE,
  GET_SHIPPING_BILL_SUMMARY_REQUEST,
  GET_SHIPPING_BILL_SUMMARY_SUCCESS,
  GET_SHIPPING_BILL_SUMMARY_FAILURE,
  GET_MARKETPLACE_VENDORS_REQUEST,
  GET_MARKETPLACE_VENDORS_SUCCESS,
  GET_MARKETPLACE_VENDORS_FAILURE,
  GET_MARKETPLACE_BILLING_SUMMARY_REQUEST,
  GET_MARKETPLACE_BILLING_SUMMARY_SUCCESS,
  GET_MARKETPLACE_BILLING_SUMMARY_FAILURE,
  GET_FILES_REQUEST,
  GET_FILES_SUCCESS,
  GET_FILES_FAILURE,
  GET_FILES_CSV_REQUEST,
  GET_FILES_CSV_SUCCESS,
  GET_FILES_CSV_FAILURE,
}, 'reports');

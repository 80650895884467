import React from 'react';
import Button from '@mui/material/Button';

export default function ErrorBoundaryMessage() {
  return (
    <div style={{ textAlign: 'center', padding: 15, backgroundColor: 'white' }}>
      <h2>Something went wrong.</h2>
      <div className="row">
        <div className="col-sm-3"></div>
        <div className="col-sm-6" style={{ textAlign: 'left', marginTop: 15 }}>
          The website encountered an unexpected error.<br />
          You can reload the page, or press the button below to clear the cache and reload (this usually is enough to fix it).<br />
          A message has been sent to our IT team with details about what happened, and we will do our best to fix this problem quickly.<br />
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <Button color="primary" variant="outlined" onClick={() => window.location.reload()}>
          Clear Cache and Reload
        </Button>
      </div>
    </div>
  );
}
import React from 'react';
import type { Auth } from '../../types/Auth';

const EtsLink = ({ show }) => {
  if (show) {
    return <li><a href="http://ets.fitnessrepairparts.com">ETS</a></li>
  }

  return null;
}

const ServicerLink = ({ show }) => {
  if (show) {
    return <li><a href="/part_buyout.php">Sell Us Parts</a></li>
  }

  return null;
}

const VendorLink = ({ show, vendor_id }) => {
  if (show) {
    return <li><a href={'/marketplace/vendors/' + vendor_id + '/dashboard'}>Marketplace</a></li>
  }

  return null;
}

interface Props {
  auth: Auth;
}

const AccountLinks = ({ auth }: Props) => {
  if (auth.isAuthenticated) {
    const accountLinks = auth.session_data.user?.servicer || auth.session_data.user?.ets || auth.session_data.user?.vendor;
    if (!accountLinks) {
      return (
        <li className="nav_btn_main tsm_clear nav_last">
          <a href="/account.php">My Account</a>
        </li>
      );
    } else {
      return (
        <li className="nav_btn_main tsm_clear nav_last">
          <a href="/account.php">My Account</a>
          <div className="nav_arrow"><div className="inner_arrow"></div></div>
          <ul id="account_list" style={{ width: '160px', marginLeft: '-30px' }}>
            <EtsLink show={auth.session_data.user?.ets} />
            <ServicerLink show={auth.session_data.user?.servicer} />
            <VendorLink show={auth.session_data.user?.vendor} vendor_id={auth.session_data.user?.account?.vendor?.id} />
            <li><a href="/account.php">Account Settings</a></li>
          </ul>
        </li>
      );
    }
  }

  return null;
}

export default AccountLinks;

import { combineReducers } from 'redux-immutable';
import types from './types';

export function product(state = {}, action) {
  switch(action.type) {
    case types.GET_PRODUCT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function manufacturers(state = [], action) {
  switch(action.type) {
    case types.GET_MANUFACTURERS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

const reducer = combineReducers({
  product,
  manufacturers
});

export default reducer;


import ajax from '@developers/utils/ajax';
import Immutable from 'immutable';
import { config } from '../../utils/config';

const api = config('api_url');

/**
 * If the action contains a key called `promise`, the middleware will assume the promise is a function that contains an ajax request and call it.
 * 
 */
const promiseMiddleware = (store) => (next) => (action) => {
  const { promise, types, props, ...rest } = action;

  if (!promise) {
    return next(action);
  } else {
    // Deprecation warning
    console.warn('[promiseMiddleware] Using `promise` to make ajax calls is deprecated. Instead you should use `ajax` and assign it a plain object.');
  }

  const [REQUEST, SUCCESS, FAILURE] = types;

  next({...rest, type: REQUEST});

  let user = null;

  // If props are passed in, check if user is passed
  if (props && typeof props.user !== 'undefined') {
    if (Immutable.Iterable.isIterable(props.user)) {
      user = props.user.toJS();
    } else {
      user = props.user;
    }
  } else if (props && typeof props.auth !== 'undefined' && (props.auth.user || props.auth.get('user'))) {
    let auth = props.auth;
    if (Immutable.Iterable.isIterable(props.auth)) {
      auth = props.auth.toJS();
    }
    user = auth.user;
  } else {
    const state = store.getState();

    if (Immutable.Iterable.isIterable(state) && state.getIn(['auth', 'user'])) {
      user = state.getIn(['auth', 'user']).toJS();
    } else if (Immutable.Iterable.isIterable(state) && state.get('user')) {
      user = state.get('user').toJS();
    }
  }

  return new Promise(resolve => {
    promise(req => formatRequest(req, user)).then(r => {
      if (!r.ok) {
        try {
          const failedResp = Promise.resolve(r.json());
          failedResp.then(payload => {
            next({...rest, status: r.status, ok: false, payload, type: FAILURE});
            resolve();
          });
        } catch (error) {
          next({...rest, ok: false, error, type: FAILURE});
          resolve();
        }
      } else {
        const contentType = r.headers.get('content-type');
        // Check if the server returned text instead of json
        //if (contentType.indexOf('text') !== -1) {
          //// The server returned text
          //r.text().then(payload => {
            //next({...rest, status: r.status, ok: r.ok, payload, type: SUCCESS});
            //resolve();
          //});
        //} else {
          // Assume the response was json
          try {
            r.json().then(payload => {
              next({...rest, status: r.status, ok: r.ok, payload, type: SUCCESS});
              resolve();
            });
          } catch (error) {
            next({...rest, ok: false, error, type: FAILURE});
            resolve();
          }
        //}
      }
    })
    .catch(error => {
      next({...rest, ok: false, error, type: FAILURE});
      resolve();
    });
  });
};

export default promiseMiddleware;

/**
 * Formats an AJAX request to hit the FRP server.
 * Expects the object passed to the frp-library ajax function.
 */
const formatRequest = (request = null, user = null) => {
    if (request && typeof request === 'object') {
        if (typeof request.url !== 'undefined' && request.url) {
            request.url = api + request.url;
        }

        // Add user API token if we have a user
        if (typeof user !== 'undefined' && user && typeof user.api_token !== 'undefined' && user.api_token != '') {
          const apiToken = user.api_token;
          if (typeof request.data !== 'undefined' && request.data) {
            request.data = { 'api-token': apiToken, ...request.data };
          } else {
            request.data = { 'api-token': apiToken };
          }
        }
    }

  return ajax(request);
};

import { url_prefix as prefix } from '../../config';
import types from './types';

export function getLocation(id) {
  return {
    types: [
      types.GET_LOCATION_REQUEST,
      types.GET_LOCATION_SUCCESS,
      types.GET_LOCATION_FAILURE
    ],
    ajax: {
      url: prefix + '/WH/locations/' + id,
    }
  }
}

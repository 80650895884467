import { config } from '../../utils/config';
import { url_prefix as prefix } from '../config';
import types from './types';

const fitplus_api = config('fitplus_api_url');

export function getTerms() {

  return {
    types: [
      types.GET_TERMS_REQUEST,
      types.GET_TERMS_SUCCESS,
      types.GET_TERMS_FAILURE
    ],
    ajax: {
      url: fitplus_api + '/terms'
    }
  };
}

export function getTerm(id) {
  return {
    types: [
      types.GET_TERM_REQUEST,
      types.GET_TERM_SUCCESS,
      types.GET_TERM_FAILURE
    ],
    ajax: {
      url: fitplus_api + '/terms/' + id,
    }
  };
}

export function createTerm(term, definition) {
  return {
    types: [
      types.SAVE_TERM_REQUEST,
      types.SAVE_TERM_SUCCESS,
      types.SAVE_TERM_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: fitplus_api + '/terms',
      data: {
        term: term,
        definition: definition
      }
    }
  };
}

export function findTerm(term) {
  return {
    types: [
      types.FIND_TERM_REQUEST,
      types.FIND_TERM_SUCCESS,
      types.FIND_TERM_FAILURE
    ],
    ajax: {
      url: fitplus_api + '/terms/find',
      data: {
        term: term
      }
    },
    term
  };
}

export function updateTerm(id, definition) {

  return {
    types: [
      types.UPDATE_TERM_REQUEST,
      types.UPDATE_TERM_SUCCESS,
      types.UPDATE_TERM_FAILURE
    ],
    ajax: {
      method: 'PUT',
      url: fitplus_api + '/terms/' + id,
      data: { definition: definition }
    }
  };
}

export function deleteTerm(id) {
  return {
    types: [
      types.DELETE_TERM_REQUEST,
      types.DELETE_TERM_SUCCESS,
      types.DELETE_TERM_FAILURE
    ],
    ajax: {
      method: 'DELETE',
      url: fitplus_api + '/terms/' + id,
      data: {}
    }
  };
}

export function createSynonym(term, id) {
  return {
    types: [
      types.SAVE_SYNONYM_REQUEST,
      types.SAVE_SYNONYM_SUCCESS,
      types.SAVE_SYNONYM_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: fitplus_api + '/synonyms',
      data: {
        dictionary_id: id,
        term: term
      }
    }
  };
}

export function detachSynonym(synonym_id, id) {
  return {
    types: [
      types.DETACH_SYNONYM_REQUEST,
      types.DETACH_SYNONYM_SUCCESS,
      types.DETACH_SYNONYM_FAILURE
    ],
    ajax: {
      method: 'PUT',
      url: fitplus_api + '/terms/' + id + '/remove_synonym',
      data: { synonym_id: synonym_id }
    }
  };
}

export function resetTerm() {
  return {
    type: types.RESET_TERM
  };
}

export function resetNotification() {
  return {
    type: types.RESET_NOTIFICATION
  };
}

export function getSimilarAccounts(id) {
  return {
    types: [
      types.GET_SIMILAR_ACCOUNTS_REQUEST,
      types.GET_SIMILAR_ACCOUNTS_SUCCESS,
      types.GET_SIMILAR_ACCOUNTS_FAILURE
    ],
    ajax: {
      method: 'GET',
      url: prefix + '/accounts/' + id + '/similar'
    }
  };
}

export function clearSimilarAccounts() {
  return {
    type: types.CLEAR_SIMILAR_ACCOUNTS
  };
}

export function mergeAccounts(remove, merge_into) {
  return {
    types: [
      types.MERGE_ACCOUNTS_REQUEST,
      types.MERGE_ACCOUNTS_SUCCESS,
      types.MERGE_ACCOUNTS_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/accounts/merge',
      data: {
        remove,
        merge_into
      }
    }
  };
}

export function getAccount(id, type) {
  return {
    types: [
      types.GET_ACCOUNT_REQUEST,
      types.GET_ACCOUNT_SUCCESS,
      types.GET_ACCOUNT_FAILURE
    ],
    ajax: {
      url: prefix + '/accounts/' + id,
      data: { with: 'users' }
    },
    account_type: type
  };
};

export function clearAccount(type) {
  return {
    type: types.CLEAR_ACCOUNT,
    payload: type
  };
}

export function getStates() {
  return {
    types: [
      types.GET_STATES_REQUEST,
      types.GET_STATES_SUCCESS,
      types.GET_STATES_FAILURE
    ],
    ajax: {
      url: prefix + '/states',
    }
  };
};

export function getExpandedShippingRates(values) {
  return {
    types: [
      types.GET_EXPANDED_SHIPPING_RATES_REQUEST,
      types.GET_EXPANDED_SHIPPING_RATES_SUCCESS,
      types.GET_EXPANDED_SHIPPING_RATES_FAILURE,
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/shipping/debug-rates',
      data: values
    }
  };
};

export function clearExpandedRates() {
  return {
    type: types.CLEAR_EXPANDED_SHIPPING_RATES
  };
};

export function getProductsWithoutVendors(page = 1, paginate = 25) {
  return {
    types: [
      types.GET_PRODUCTS_WITHOUT_VENDORS_REQUEST,
      types.GET_PRODUCTS_WITHOUT_VENDORS_SUCCESS,
      types.GET_PRODUCTS_WITHOUT_VENDORS_FAILURE
    ],
    ajax: {
      url: prefix + '/products/novendors',
      data: { page, paginate}
    }
  }
};

export function getShippingBillSummary(file = null, service = null, email = null) {
  return {
    types: [
      types.GET_SHIPPING_BILL_SUMMARY_REQUEST,
      types.GET_SHIPPING_BILL_SUMMARY_SUCCESS,
      types.GET_SHIPPING_BILL_SUMMARY_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/reports/analyze-bill',
      data: {file, service, email}
    }
  }
};

export function getMarketplaceVendors() {
  return {
    types: [
      types.GET_MARKETPLACE_VENDORS_REQUEST,
      types.GET_MARKETPLACE_VENDORS_SUCCESS,
      types.GET_MARKETPLACE_VENDORS_FAILURE
    ],
    ajax: {
      url: prefix + '/marketplace/vendors',
      data: { 
        paginate: false, 
        approved: true
      }
    }
  }
};

export function getFiles(filters, page = 1) {
  return {
    types: [
      types.GET_FILES_REQUEST,
      types.GET_FILES_SUCCESS,
      types.GET_FILES_FAILURE
    ],
    ajax: {
      url: prefix + '/files',
      data: {
        filters,
        page,
        paginate: 50,
        add_dimensions: true
      }
    }
  }
};

export function getFilesCsv(filters, csv_fields) {
  return {
    types: [
      types.GET_FILES_CSV_REQUEST,
      types.GET_FILES_CSV_SUCCESS,
      types.GET_FILES_CSV_FAILURE
    ],
    ajax: {
      url: prefix + '/files',
      data: {
        orderBy: 'file_date_uploaded',
        direction: 'desc',
        format: 'csv',
        filters,
        csv_fields
      }
    }
  }
}

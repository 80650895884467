import React from 'react';
import type { AdminMenu as IAdminMenu } from '../../types/Nav';

interface Props {
  menus: IAdminMenu;
}

function AdminMenu({ menus }: Props) {
  if (menus) {
    const count = Object.keys(menus).length;
    const width = (count * 165);
    const marginLeft = '-' + (width - 280) + 'px';

    return (
      <li id="menu_admin" className="nav_btn_main tsm_admin">
        <a href="/admin_index.php">Administration</a>
        <div className="nav_arrow"><div className="inner_arrow"></div></div>
        <ul style={{ width, marginLeft }}>
          {Object.entries(menus).map(([title, links]) => (
            <li key={title}>
              <h4>{title}</h4>
              <ul>
                {Object.entries(links).map(([label, link]) => {
                  if (label === 'Chat') {
                    return (<li key={label}><a href={link} id="show_chat">{label}</a></li>);
                  } else {
                    return (<li key={label}><a href={link}>{label}</a></li>);
                  }
                })}
              </ul>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return null;
}

export default AdminMenu;

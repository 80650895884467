import React from 'react';
import { useAuth } from '@developers/single-sign-on';

export default function AdminSideMenu(props) {
  const auth = useAuth();
  const admin = auth.isAuthenticated && auth.session_data.admin

  if (!auth.verifyMeta.loading && !auth.loginMeta.loading && admin && props.show) {
    return (
      <AdminLinks auth={auth} />
    )
  }

  return null;
}

function AdminLinks(props) {
  React.useEffect(() => {
    chat_register(props.auth.session_data.user.user_id, false); //register that user is alive
    chat_listen(props.auth.session_data.user.user_id, 1); //check for open chats and start repeated polling
  }, []);

  return <AdminSidebar />
}

function AdminSidebar(props) {
  React.useEffect(() => {
    jq_generic('admin_menu_items.php', 'slidemenubar', calc_flyout_height);
    window.themenu = document.getElementById('slidemenubar').style;

    // Dynamically update flyout reveal on window resize
    $(window).resize(function () {
      setSlidemenu();
      $('#slidemenubar').css('left', ((parseInt(slidemenu_width)-parseInt(slidemenu_reveal))*-1)+'px');
      calc_flyout_height();
      $('#slidemenubar').css('height', slidemenu_height);
    });
  }, []);

  function handleMouseOver() {
    if (typeof themenu !== 'undefined' && window.pull) {
      window.pull();
    }
  }

  function handleMouseOut() {
    if (typeof themenu !== 'undefined' && window.draw) {
      window.draw();
    }
  }

  const leftPos = ((parseInt(slidemenu_width) - parseInt(slidemenu_reveal)) * -1) + 'px';

  return (
    <div 
      id="slidemenubar" 
      style={{
        backgroundColor: '#FFF',
        zIndex: 5,
        position: 'fixed',
        overflow: 'hidden',
        left: leftPos,
        top: slidemenu_top,
        width: slidemenu_width,
        height: slidemenu_height,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
    </div>
  )
}
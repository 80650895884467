import ajaxBase from '@developers/utils/ajax';
import { getToken } from '@developers/single-sign-on';
import { config } from '../utils/config';
import { isServer } from '../utils/isServer';

const prefix = config('api_url');

function ajax(request) {
  const token = getToken(config('sso_storage_driver'));
  
  let headers = {
    SSO: token,
  };

  if (isServer()) {
    headers['X-API-key'] = config('frpapi_key');
  }

  const requestWithHeaders = {...request, headers: headers};

  return ajaxBase(requestWithHeaders);
}

export { prefix, ajax };
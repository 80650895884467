import React from 'react';

declare var submit_search;

/**
 * A lot of this functionality is still held in legacy code.
 * This isn't desirable, but until we don't use the legacy search bar it does prevent duplicate logic.
 * 
 * Reference: legacy-frp/public_html/js/header.js
 */
export default function MobileSearch() {
  const form = React.useRef();

  function handleSubmit(e) {
    // Globally available from legacy-frp/public_html/js/common.js
    // Manipulates the form object before submitting
    submit_search(form.current);
  }

  return (
    <div className="navbar-header">
      <form className="navbar-form pull-left navbar-toggle" role="search" id="collapseSearch" action="/part_search/PartNumberSearch/" method="post" onSubmit={handleSubmit} ref={form}>
        <div className="input-group">
          <input type="text" className="form-control" placeholder="MODEL #, PART #, CATALOG #, SEARCH TERM" name="keywords" />
          <span className="input-group-btn">
            <button type="submit" className="btn btn-default"><span className="glyphicon glyphicon-search"></span></button>
          </span>
        </div>
      </form>
      <button type="button" className="navbar-toggle" id="navbarToggle" data-toggle="collapse" data-target="#primary_nav">
        <span className="sr-only">Toggle navigation</span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
    </div>
  );
}

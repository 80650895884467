const comm100 = () => {
  window.Comm100API={chat_buttons: []}; 
  Comm100API.chat_buttons.push({code_plan: 'd1070000-0000-0000-0000-008e0001655a', div_id: 'comm100-button-d1070000-0000-0000-0000-008e0001655a'});
  Comm100API.site_id=91482;
  Comm100API.main_code_plan='d1070000-0000-0000-0000-008e0001655a';
  let lc=document.createElement('script');
  lc.type='text/javascript';
  lc.async=true;lc.src='//chatserver.comm100.com/livechat.ashx?siteId='+Comm100API.site_id;
  let s=document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(lc, s);
}

export default comm100;

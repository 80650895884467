import React from 'react';
// import { useHistory } from 'react-router-dom';
import { useRouter } from 'next/router';
import { config } from '../../utils/config';
import { useAuth } from '@developers/single-sign-on';

export default function Analytics({ children }) {
  const [enableAnalytics, toggleAnalytics] = React.useState(true);
  let router = useRouter();
  const auth = useAuth();

  React.useEffect(() => {
    // On every page change, send an event to Google analytics
    router.events.on('routeChangeComplete', (url) => {
      if (isGoogleLoaded()) {
        gtag('config', config('google_analytics_id'), { 'page_path': url });
      }
      if (isMicrosoftLoaded()) {
        window.uetq.push('event', 'page_view', { 'page_path': url }); 
      }
    });
  }, []);

  React.useEffect(() => {
    if (!auth.verifyMeta.loading && !auth.loginMeta.loading) {

      const shouldEnableAnalytics = (auth.session_data.admin || auth.session_data.on_site) && config('inProduction') ? false : true;
      
      toggleAnalytics(shouldEnableAnalytics);
    }
  }, [auth.isAuthenticated, auth.verifyMeta.loading, auth.loginMeta.loading]);

  React.useEffect(() => {
    if (enableAnalytics) {
      attachGoogleScript();
      attachMicrosoftScript();
      attachGoogleAdWordsScript();
      attachMicrosoftClarityScript();
    } else {
      detachGoogleScript();
      detatchMicrosoftScript();
      detachMicrosoftClarityScript();
      detachGoogleAdWordsScript();
    }
  }, [enableAnalytics]);

  function isGoogleLoaded() {
    return typeof gtag === 'function';
  }

  function isMicrosoftLoaded() {
    return !!window.uetq;
  }

  function attachGoogleScript() {
    if (!isGoogleLoaded()) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('async', '');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config('google_analytics_id');
      document.getElementsByTagName('head')[0].appendChild(script);

      let gtagScript = document.createElement('script');
      gtagScript.setAttribute('data-id', 'gtagFunctions');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '`+ config('google_analytics_id') + `');
      `;
      document.getElementsByTagName('head')[0].appendChild(gtagScript);
    }
  }

  function attachGoogleAdWordsScript() {
    if (!isGoogleLoaded()) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('async', '');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + config('google_ad_words_id');
      document.getElementsByTagName('head')[0].appendChild(script);

      let gtagScript = document.createElement('script');
      gtagScript.setAttribute('data-id', 'gtagFunctions');
      gtagScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', '`+ config('google_ad_words_id') + `');
      `;
      document.getElementsByTagName('head')[0].appendChild(gtagScript);
    }
  }

  function attachMicrosoftClarityScript() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      (function(c,l,a,r,i,t,y) {
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "` + config('microsoft_clarity_id') + `");
    `;

    document.getElementsByTagName('head')[0].appendChild(script);
  }

  function detachMicrosoftClarityScript() {
    const script = document.querySelector('script[src="https://www.clarity.ms/tag/' + config('microsoft_clarity_id') + '"');

    if (script) {
      script.parentNode.removeChild(script)
    }
  }

  function detachGoogleScript() {
    if (isGoogleLoaded()) {
      const script = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=' + config('google_analytics_id') + '"]');
      script.parentNode.removeChild(script);

      const gtagScript = document.querySelector('script[data-id="gtagFunctions"]');
      gtagScript.parentNode.removeChild(gtagScript);

      gtag = undefined;
    }
  }

  function detachGoogleAdWordsScript() {
    if (isGoogleLoaded()) {
      const script = document.querySelector('script[src="https://www.googletagmanager.com/gtag/js?id=' + config('google_ad_words_id') + '"]');
      script.parentNode.removeChild(script);

      const gtagScript = document.querySelector('script[data-id="gtagFunctions"]');
      gtagScript.parentNode.removeChild(gtagScript);

      gtag = undefined;
    }
  }

  function attachMicrosoftScript() {
    if (!isMicrosoftLoaded()) {
      (function(w, d, t, r, u){let f, n, i;w[u]=w[u]||[], f=function(){let o={ti: config('microsoft_uetq_key')};o.q=w[u], w[u]=new UET(o), w[u].push('pageLoad')}, n=d.createElement(t), n.src=r, n.async=1, n.onload=n.onreadystatechange=function(){let s=this.readyState;s&&s!=='loaded'&&s!=='complete'||(f(), n.onload=n.onreadystatechange=null)}, i=d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)})(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
    }
  }

  function detatchMicrosoftScript() {
    const script = document.querySelector('script[src="//bat.bing.com/bat.js"]');
    if (script) {
      script.parentNode.removeChild(script);
    }
    window.uetq = undefined;
  }

  return children;
}
import React from 'react';
import { useAjax } from '@developers/use-ajax';
import { getStates as getStatesAjax } from '../ajax/states';
import type { State } from '../types/State';
import type { AjaxMeta } from '@developers/types/common/AjaxMeta';

interface StatesContext {
  states: State[];
  getStates: () => void;
  getStatesMeta: AjaxMeta;
}

export const StatesContext = React.createContext({} as StatesContext);

export function useStates() {
  const { states, getStates, getStatesMeta } = React.useContext(StatesContext);
  
  React.useEffect(() => {
    if (states.length === 0 && !getStatesMeta.loading) {
      getStates();
    }
  }, []);

  return states;
}

export function StatesProvider({ children }) {
  const [states, getStates, getStatesMeta] = useAjax(getStatesAjax, [] as State[]);

  return (
    <StatesContext.Provider value={{ states, getStates, getStatesMeta }}>
      {children}
    </StatesContext.Provider>
  );
}
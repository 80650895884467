const Sizes = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
  xs: 'xs',
  xxs: 'xxs'
} as const;

/**
 * Get size of screen based on bootstrap breakpoints
 */
function getSize(): keyof typeof Sizes {
  const width = typeof window !== 'undefined' ? window.innerWidth : 1000;

  // All sizes
  if (width >= 1200) {
    return Sizes.lg;
  } else if (width >= 992) {
    return Sizes.md;
  } else if (width >= 768) {
    return Sizes.sm;
  } else if (width >= 475) {
    return Sizes.xs;
  } else {
    return Sizes.xxs;
  }
}  

function breakAt(sizeCheck: keyof typeof Sizes) {
  const size = getSize();
  switch (sizeCheck) {
    case Sizes.lg:
    case Sizes.md:
      return size === Sizes.lg;
    case Sizes.sm:
      return size === Sizes.lg || size === Sizes.md;
    case Sizes.xs:
    case Sizes.xxs:
    default:
      return true;
  }
}

export function useScreenSize() {
  return {
    size: getSize(),
    breakAt: breakAt,
  };
}
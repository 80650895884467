import addNamespace from '../addNamespace';

const GET_SERVICERS_BY_ZIP_REQUEST = 'GET_SERVICERS_BY_ZIP_REQUEST';
const GET_SERVICERS_BY_ZIP_SUCCESS = 'GET_SERVICERS_BY_ZIP_SUCCESS';
const GET_SERVICERS_BY_ZIP_FAILURE = 'GET_SERVICERS_BY_ZIP_FAILURE';

export default addNamespace({
    GET_SERVICERS_BY_ZIP_REQUEST,
    GET_SERVICERS_BY_ZIP_SUCCESS,
    GET_SERVICERS_BY_ZIP_FAILURE,   
}, 'service_providers');
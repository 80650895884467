import { url_prefix as prefix } from '../config';
import types from './types';

export function getCartAjax (admin = false) {
  const data = admin ? { admin } : {};
  return {
    types: [
      types.GET_CART_REQUEST,
      types.GET_CART_SUCCESS,
      types.GET_CART_FAILURE
    ],
    ajax: {
      url: prefix + '/cart',
      data,
    }
  };
}

export function setAdminCheckout(value) {
  return {
    types: [
      types.SET_ADMIN_CHECKOUT_REQUEST,
      types.SET_ADMIN_CHECKOUT_SUCCESS,
      types.SET_ADMIN_CHECKOUT_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/checkout/admin-checkout',
      data: {value}
    },
    admin: value
  };
}

export function updateCartAjax (productNumber, qty, index = false) {
  return {
    types: [
      types.UPDATE_CART_REQUEST,
      types.UPDATE_CART_SUCCESS,
      types.UPDATE_CART_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/set/' + productNumber,
      data: { qty, index }
    },
    product: productNumber
  };
}

export function removeItemAjax (productNumber, product, index) {
  return {
    types: [
      types.REMOVE_ITEM_REQUEST,
      types.REMOVE_ITEM_SUCCESS,
      types.REMOVE_ITEM_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/remove/' + productNumber,
    },
    product,
    index
  };
}

export function updateZip (passedZip = '') {
  const zip = passedZip === '' ? passedZip : '/' + passedZip;

  return {
    types: [
      types.UPDATE_ZIP_REQUEST,
      types.UPDATE_ZIP_SUCCESS,
      types.UPDATE_ZIP_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/update/zip' + zip,
    },
  };
}

export function updateSelectedShipping (rate) {
  const newRate = rate.toJS();
  return {
    types: [
      types.UPDATE_SHIPPING_REQUEST,
      types.UPDATE_SHIPPING_SUCCESS,
      types.UPDATE_SHIPPING_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/update/shipping',
      data: { rate: newRate }
    },
  };
}

export function clearRemovedProducts () {
  return {
    type: types.CLEAR_REMOVED_PRODUCTS
  };
}

export function getCartCount() {
  return {
    types: [
      types.GET_CART_COUNT_REQUEST,
      types.GET_CART_COUNT_SUCCESS,
      types.GET_CART_COUNT_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/count'
    }
  };
}

export function updateCartCount(count) {
  return {
    type: types.UPDATE_CART_COUNT,
    payload: { count }
  };
}

export function addToCart(id, qty) {
  return {
    types: [
      types.ADD_TO_CART_REQUEST,
      types.ADD_TO_CART_SUCCESS,
      types.ADD_TO_CART_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/add/' + id,
      data: { qty },
    }
  };
}

export function resetCart() {
  return {
    type: types.RESET_CART,
  };
}

export function getCoupon(couponCode) {
  return {
    types: [
      types.GET_COUPON_REQUEST,
      types.GET_COUPON_SUCCESS,
      types.GET_COUPON_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/coupon/add/' + couponCode,
    }
  };
}

export function removeCoupon() {
  return {
    types: [
      types.REMOVE_COUPON_REQUEST,
      types.REMOVE_COUPON_SUCCESS,
      types.REMOVE_COUPON_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/coupon/remove',
    }
  };
}

export function clearAlerts() {
  return {
    type: types.CLEAR_ALERTS,
  };
}

export function saveQuoteAjax(data = null) {
  return {
    types: [
      types.SAVE_QUOTE_REQUEST,
      types.SAVE_QUOTE_SUCCESS,
      types.SAVE_QUOTE_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/cart/quote',
      data
    }
  };
}

export function getQuote(quote) {
  return {
    types: [
      types.POPULATE_QUOTE_REQUEST,
      types.POPULATE_QUOTE_SUCCESS,
      types.POPULATE_QUOTE_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/quote/' + quote
    }
  }; 
}

export function rewriteCartAjax(newCart, options = {}) {
  return {
    types: [
      types.REWRITE_CART_REQUEST,
      types.REWRITE_CART_SUCCESS,
      types.REWRITE_CART_FAILURE
    ],
    ajax: {
      method: 'get',
      url: prefix + '/cart/rewrite',
      data: {
        cart: newCart,
        content: true,
        ...options
      }
    }
  };
}

export function clearWarranty() {
  return {
    type: types.CLEAR_WARRANTY,
  };
}

/**
 * Runs whatever AJAX action was passed
 * through the cart alert popup for a button
 */
export function alertActionAjax (url, data = {}, method = 'GET') {
  return {
    types: [
      types.ALERT_ACTION_REQUEST,
      types.ALERT_ACTION_SUCCESS,
      types.ALERT_ACTION_FAILURE
    ],
    ajax: {
      url,
      method,
      data,
    }
  };
}

export function requireSignature (required = true) {
  return {
    types: [
      types.REQUIRE_SIGNATURE_REQUEST,
      types.REQUIRE_SIGNATURE_SUCCESS,
      types.REQUIRE_SIGNATURE_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/cart/shipping/require-signature',
      data: { required },
    }
  };
}

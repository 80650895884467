import { ajax, prefix } from './common';

export async function getUser() {
  return await ajax({
    url: prefix + '/auth/user'
  });
}

export async function getTimeToExpiration() {
  return await ajax({
    url: prefix + '/auth/activity',
  });
}

export async function resetExpirationTimer() {
  return await ajax({
    method: 'PUT',
    url: prefix + '/auth/activity',
  })
}
import env from "@beam-australia/react-env";

type Values = {
  readonly inProduction: any;
  readonly environment: any;
  readonly google_analytics_id: any;
  readonly sso_storage_driver: any;
  readonly use_sentry: any;
  readonly sentry_dsn: any;
  readonly release_name: any;
  readonly performance_tracking_rate: any;
  readonly microsoft_uetq_key: any;
  readonly microsoft_clarity_id: any;
  readonly api_url: any;
  readonly fitplus_api_url: any;
  readonly file_host: any;
  readonly static_asset_prefix: any;
  readonly frpapi_key: any;
  readonly frp_phone: any;
  readonly hot_jar_id: any;
}

const values: Values = {
  inProduction: env('ENVIRONMENT') === 'production',
  environment: env('ENVIRONMENT'),
  google_analytics_id: env('GOOGLE_ANALYTICS_ID'),
  sso_storage_driver: env('SSO_STORAGE_DRIVER'),
  use_sentry: !!env('SENTRY_DSN'),
  sentry_dsn: env('SENTRY_DSN'),
  release_name: env('RELEASE_NAME'),
  performance_tracking_rate: env('PERFORMANCE_TRACKING_RATE'),
  microsoft_uetq_key: env('MICROSOFT_UETQ_KEY'),
  microsoft_clarity_id: env('MICROSOFT_CLARITY_ID'),
  api_url: env('FRPAPI'),
  fitplus_api_url: env('FITPLUS'),
  file_host: env('FILE_HOST'),
  static_asset_prefix: env('ASSETS'),
  frpapi_key: process.env.API_KEY || '',
  frp_phone: '636.634.2202',
  hot_jar_id: env('HOT_JAR_ID'),
};

export function config(key: keyof Values) {
  if (key in values) {
    if (typeof values[key] === 'undefined' && !config('inProduction')) {
      console.warn('[config] Accessing config value "' + key + '" but the value is undefined. This most likely means it is present in the JS config object, but missing from another source like an env. You may have forgotten to restart your build.');
    }

    return values[key];
  } else {
    if (!config('inProduction')) {
      console.error('[config] Attempted to access config value "' + key + '" which does not exist. You may have mistyped the key, or forgotten to set it.');
    }
  }
}

import { prefix, ajax } from './common';

export async function getStates() {
  return await ajax({
    url: prefix + '/states',
  });
}

export async function getSalesTax(id: number) {
  return await ajax({
    url: prefix + '/states/' + id + '/sales-tax',
  });
}
import React from 'react';

interface Props {
  text?: string;
}

function Banner(props: Props) {
  if (props.text) {
    return (
      <div style={{ width: '99%', margin: 'auto', textAlign: 'center', backgroundColor: '#8b0000', marginTop: '10px', marginBottom: '10px', borderRadius: '5px' }}>
        <h4 style={{ marginTop: '0px', padding: '5px', color: 'white', fontFamily: '"Open Sans", Arial, sans-serif', fontWeight: 'bold' }}>{props.text}</h4>
      </div>
    );
  } 
  return null;
}

export default Banner;

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import ajaxMiddleware from './middleware/ajax-middleware';
import promiseMiddleware from './middleware/promise-middleware';
import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';
import * as reducers from './reducers';
import { reducer as meta } from '@developers/redux-meta';
import ssoMiddleware from '@developers/single-sign-on/middleware/sso-middleware';
import { config } from '../utils/config';

function condenseReducers(reducers) {
  let condensed = {
    form: formReducer,
    meta
  };

  Object.keys(reducers).forEach(key => {
    condensed[key] = reducers[key]
  })

  return condensed
}

export default function configureStore() {
  const condensed = condenseReducers(reducers);
  const reducer = combineReducers(condensed)

  const store = createStore(
    reducer,
    applyMiddleware(thunk, ssoMiddleware(config('sso_storage_driver')), ajaxMiddleware, promiseMiddleware)
  )

  return store
}

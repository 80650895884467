import * as actions from './actions';

const usePaymentProfiles = true;

const getStepFromPage = (page) => {
  switch (page) {
    case 'shipping':
    case 'account-search':
    default:
      return 0;
    case 'billing':
      return 1;
    case 'review':
      return 2;
    case 'thankyou':
      return 3;
  }
}

/*
 * Navigate between pages through an action
 */
export function navigate(pageName = 'shipping', changePage = undefined) {
  const step = getStepFromPage(pageName);
  return function (dispatch, getState) {
    dispatch(actions.changeStep(step)).then(() => {
      if (typeof changePage !== 'undefined') {
        changePage(pageName);
      }
    });
  }
}

export function navigateAfterStep(pageName = 'shipping', changePage = undefined, dispatch, afterTransition = null) {
  const step = getStepFromPage(pageName);
  dispatch(actions.changeStep(step)).then(() => {
    if (typeof changePage !== 'undefined') {
      changePage(pageName);
      if (afterTransition) {
        setTimeout(() => {
          afterTransition();
        }, 10000);
      }
    }
  });
}

/*
 * Submit shipping - if valid go to billing step
 */
export function goToBilling(values, skip = false, transition = undefined, willCall = false, address_id = '', requiredFields, nextPage = 'billing') {
  return function (dispatch, getState) {
    if (willCall) {
      skip = true;
    }
    values = values.set('will_call', willCall);
    dispatch(actions.getShippingAjax(values, skip, address_id)).then(() => {
      const state = getState();
      const isRequired = (name) => {
        return requiredFields.indexOf(name) !== -1;
      }

      if (nextPage !== 'shipping') {
        if (state.getIn(['checkout', 'shipping', 'validation', 'valid']) 
          && (!isRequired('email') || state.getIn(['checkout', 'shipping', 'validation', 'cart', 'pending', 'purchase_pending_saved_shipping', 'email']))
          && (!isRequired('phone') || state.getIn(['checkout', 'shipping', 'validation', 'cart', 'pending', 'purchase_pending_saved_shipping', 'phone']))
          && typeof transition !== undefined) {
          // if address is valid and we are clear to move onto billing step, then go to billing
          navigateAfterStep(nextPage, transition, dispatch);
        } else if (nextPage === 'account-search') {
          navigateAfterStep(nextPage, transition, dispatch);
        } else if (nextPage === 'warranty') {
          navigateAfterStep(nextPage, transition, dispatch);
        }
      }
    });
  }
}

export function setAsUser(value, updateShipping, cb = null) {
  return (dispatch) => {
    dispatch(actions.setUser(value, updateShipping)).then(() => {
      dispatch(actions.getAddresses(value)).then(() => {
        if (cb) {
          cb();
        }
      });
    })
  }
}

export function setPrevOrder(value) {
  return (dispatch, getState) => {
    dispatch(actions.orderSearch(value)).then(() => {
      const state = getState();
      dispatch(actions.setPreviousOrderNumber(value));

      // If this checkout does not already have an account number
      // manually entered in the Account field, try to add the account
      // on the order if one exists
      if (state.getIn(['checkout', 'adminCheckout', 'accountNumber']) && state.getIn(['checkout', 'adminCheckout', 'accountNumber']) > 0) {
        const id = state.getIn(['checkout', 'prevOrder', 'account']);
        if (id > 0) {
          dispatch(actions.setAdminCheckoutAccount(id));
          dispatch(actions.accountSearch(id));
        }
      }
    })
  }
}

export function setWarranty(bool, serial_number, text, cb = null) {
  return (dispatch) => {
    dispatch(actions.setWarrantyAjax(bool, serial_number, text)).then(() => {
      if (cb != null) {
        cb();
      }
    });
  }
}

export function submitCreditCard(values, billingValues, transition = undefined, nextPage = 'review') {
  return (dispatch, getState) => {
    dispatch(actions.updateCreditCard(values))
      dispatch(actions.submitBillingAjax(billingValues)).then(() => {
        const state = getState();

        if (state.getIn(['checkout', 'validStep']) >= 1 && typeof transition !== undefined) {
          // if address is valid and we are clear to move onto review step, then go to review 
          navigateAfterStep(nextPage, transition, dispatch);
        }
      });
  }
}

export function fillCardInfo(id, initialize, fillAddress) {
  // Do not use saved cards at all if not using payment profiles
  if (!usePaymentProfiles) {
    return false;
  }
  return function (dispatch, getState) {
    dispatch(actions.getCardInfo(id)).then(()=> {
      const state = getState();
      const values = {
        'credit_card_id': id,
        'cc-name': state.getIn(['checkout', 'creditCard', 'cc-name']),
        'cc-number': state.getIn(['checkout', 'creditCard', 'cc-number']),
        'cc-exp-month': state.getIn(['checkout', 'creditCard', 'cc-exp-month']),
        'cc-exp-year': state.getIn(['checkout', 'creditCard', 'cc-exp-year']),
        'organization': state.getIn(['checkout', 'creditCard', 'organization']),
      }
      const address = {
        'addr1': state.getIn(['checkout', 'creditCard', 'addr1']),
        'addr2': state.getIn(['checkout', 'creditCard', 'addr2']),
        'city': state.getIn(['checkout', 'creditCard', 'city']),
        'state': state.getIn(['checkout', 'creditCard', 'state']),
        'postal_code': state.getIn(['checkout', 'creditCard', 'postal_code']),
      }
      if (id == '') {
        initialize({});
        fillAddress({});
      } else {
        initialize(values);
        fillAddress(address);
      }
    });
  }
}

/*
 * Place order - if valid go to Thank You page
 */
export function placeOrder(creditCard, extraInfo, changePage = undefined) {
  return function (dispatch, getState) {
    dispatch(actions.placeOrderAjax(creditCard, extraInfo)).then(() => {
      const state = getState();
      if (state.getIn(['checkout', 'validStep']) >= 2) {
        if (state.getIn(['checkout', 'order', 'error'])) {
          const errorType = state.getIn(['checkout', 'order', 'message']);
          const billingErrors = ['cc-number', 'cc-csc', 'cc-date', 'No Billing Address', 'CVV does not match', 'Street address and postal code do not match.', 'AVS data is invalid or AVS is not allowed for this card type.', 'This transaction has been declined.'];
          if (billingErrors.indexOf(errorType) !== -1 || errorType.includes('international credit card')) {
            // there is a billing error, take user back to billing page
            dispatch(actions.setErrorPopup('Billing Error: ' + errorType, 'billing'))
          } else if (errorType == 'no selected shipping') {
            dispatch(actions.setErrorPopup('This postal code seems to be invalid. Please change it or try again.', 'shipping'));
          } else if (errorType !== 'order-exists') {
            dispatch(actions.setAVSPopup(errorType)); 
          } else {
            // if order already exists just display error message
            // most likely means cookies are messed up for user
            // error message will handle this so do nothing here
          }
        } else {

          // if we are clear to move onto Thank You page, then go to Thank You page
          navigateAfterStep('thankyou', changePage, dispatch);
        }
      }
    });
  }
}

/**
 * Updates the pending shipping with city/state data based on postal code
 */
export function updatePostalCode(postalCode, values) {
  return function (dispatch, getState) {
    dispatch(actions.getZipData(postalCode)).then(() => {
      const state = getState();
      //if (values.get('addr1')) {
        if (state.getIn(['checkout', 'zipData', 'city'])) {
          /**
           * Commenting out the following line. Getting zipData and then autofilling in the zip data into the form takes control away from the user and also makes our address validation useless. 
           * Address validation gives the user a suggestion of a correct address and gives them a choice of whether or not to use the suggestion. 
           * 
           * Correcting it and setting it with zipData automatically never gives the user a chance to deny the suggestion like it does during address validation.
           * 
           * values = values.set('postal_code', postalCode).set('city', state.getIn(['checkout', 'zipData', 'city'])).set('state', state.getIn(['checkout', 'zipData', 'state']));
           *  */ 
          // If we also have a street address, we have enough address info to calculate rates
          // Update pending shipping and validate address if we have addr1 (otherwise just skip validation)
          const skip = (typeof values.get('addr1') !== 'undefined' && values.get('addr1') != '') ? false : true;
          dispatch(actions.updateShippingAddress(values, skip));
        }
      //}
    });
  }
}

export default {
  ...actions,
  navigate,
  goToBilling,
  submitCreditCard,
  placeOrder,
  fillCardInfo,
  setAsUser,
  setPrevOrder,
  setWarranty,
  updatePostalCode,
};

import { url_prefix as prefix } from '../config';
import types from './types';

export function getServicersByZip(zip, distance) {
  return {
    types: [
      types.GET_SERVICERS_BY_ZIP_REQUEST,
      types.GET_SERVICERS_BY_ZIP_SUCCESS,
      types.GET_SERVICERS_BY_ZIP_FAILURE
    ],
    ajax: {
      url: prefix + '/find-servicer/zip/' + zip,
      data: {
        distance,
      },
    }
  };
}

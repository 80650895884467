// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { config } from './src/utils/config';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: config('sentry_dsn'),
  tracesSampleRate: parseFloat(config('performance_tracking_rate')),
  environment: config('environment'),
});

import { combineReducers } from 'redux-immutable';
import types from './types';
import { cartTypes } from '../cart';

export function meta(state = {showMessage: false}, action) {
  let newState = {...state};
  switch(action.type) {
    case cartTypes.ADD_TO_CART_SUCCESS:
      newState.showMessage = true;
      return newState;
    case types.CLEAR_CART_MESSAGE:
      newState.showMessage = false;
      return newState;
    default:
      return state;
  }
}

const reducer = combineReducers({
  meta
});

export default reducer;


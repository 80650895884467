import React from 'react';
import LoginPopup from '../components/LoginPopup';
import Full from '../layouts/Full';
import { Provider } from 'react-redux';
import createStore from '../redux/configureStore';
import * as Sentry from '@sentry/react';
import { config } from '../utils/config';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import mainTheme from '../themes/main'
import { AuthProvider } from '@developers/single-sign-on/AuthProvider';
import { getUser } from '../ajax/auth';
import ErrorBoundaryMessage from '../components/ErrorBoundaryMessage';
import { LoginPromptProvider } from '../hooks/LoginPromptContext';
import { StatesProvider } from '../hooks/StatesContext';
import '../styles/global.css';
import Head from 'next/head';
import Script from 'next/script';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { hotjar } from 'react-hotjar';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const store = createStore();

const theme = createTheme(mainTheme);

async function getFrpUser() {
  const response = await getUser();
  return await response.json();
}

function defaultLayout(page) {
  return <Full>{page}</Full>
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component?.getLayout || defaultLayout;
  const assetPrefix = config('static_asset_prefix');

  React.useEffect(() => {
    if (config('hot_jar_id')) {
      hotjar.initialize(config('hot_jar_id'), 6);
    }
  }, [])

  return <>
    <Head>
      <meta name="viewport" content="width=device-width, maximum-scale=1.0, minimum-scale=1.0, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta charSet="utf-8" />
      <meta httpEquiv="expires" content="0" />
      <title>Fitness and Exercise Equipment Repair Parts</title>
      {/* eslint-disable-next-line @next/next/no-sync-scripts */}
      <script src="/__ENV.js" />
      <meta property="og:title" content="FitnessRepairParts.com" />
      <meta property="og:url" content="https://www.fitnessrepairparts.com" />
      <meta property="og:type" content="company" />
      <meta property="og:image" content="https://www.fitnessrepairparts.com/images/logo_acronym_blue.jpg" />
      <meta property="og:description" content="Find spare or replacement parts for exercise and fitness equipment. Use our diagrams and parts lists to locate the right part and extend the life of your treadmill, cross-trainer, bike, elliptical, stepper, or strength machine!" />
    </Head>
    <Script strategy='beforeInteractive' src="https://code.jquery.com/jquery-3.4.1.min.js" />
    <Script src={assetPrefix + "/js/lib/moment/moment.min.js"} />
    <Script src={assetPrefix + "/js/lib/bootstrap/bootstrap.min.js"} />
    <Script src={assetPrefix + "/js/lib/es6-promise/es6-promise.min.js"} />
    <Script strategy='afterInteractive' src={assetPrefix + "/js/common.js"} />
    <Script strategy='afterInteractive' src={assetPrefix + "/js/admin_common.js"} />
    <Script strategy='afterInteractive' src={assetPrefix + "/js/header.js"} />
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Sentry.ErrorBoundary fallback={<ErrorBoundaryMessage />}>
            <AuthProvider loadSessionData={getFrpUser} storage_driver={config('sso_storage_driver')}>
              <LoginPromptProvider>
                <StatesProvider>
                  <LoginPopup />
                  {getLayout(<Component {...pageProps} />)}
                </StatesProvider>
              </LoginPromptProvider>
            </AuthProvider>
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </>;
}

export default MyApp
import { url_prefix as prefix } from '../config';
import types from './types';

export function getStates() {
  return {
    types: [
      types.GET_STATES_REQUEST,
      types.GET_STATES_SUCCESS,
      types.GET_STATES_FAILURE
    ],
    ajax: {
      url: prefix + '/states',
      data: {
        orderBy: 'state_country|desc,state_abbreviation',
        paginate: false,
      }
    }
  };
}

import Immutable from 'immutable';
import types from './types';
import { checkoutTypes } from '../checkout';

const initial = Immutable.fromJS({
  adminCheckout: false,
  count: 0,
  loading: true,
  selectedProduct: 0,
  productLoading: false,
  zipLoading: false,
  products: [],
  removed: [],
  alerts: [],
  user: 0,
  custom_properties: {
    warranty: false,
    purchase_site: 'FRP',
    serial_number: null
  }
});

const reducer = (state = initial, action) => {
  let removedProducts;
  switch (action.type) {
    case types.GET_CART_COUNT_SUCCESS:
    case types.UPDATE_CART_COUNT:
      return state.set('count', action.payload.count);
    case types.ADD_TO_CART_SUCCESS:
      //return state.set('count', action.payload.product_count.count);
      return initial.set('loading', false).mergeDeep(action.payload).set('count', action.payload.product_count.count);
    case types.GET_CART_REQUEST:
    case types.REWRITE_CART_REQUEST:
      return state.set('loading', true);
    case types.GET_CART_SUCCESS:
    case types.REWRITE_CART_SUCCESS:
    case types.REQUIRE_SIGNATURE_SUCCESS:
      return state.set('loading', false).mergeDeep(action.payload).set('count', action.payload.product_count.count);
    case types.UPDATE_CART_REQUEST:
    case types.REMOVE_ITEM_REQUEST:
      return state.set('productLoading', true).set('selectedProduct', action.product).set('zipLoading', true);
    case types.UPDATE_ZIP_REQUEST:
    case types.UPDATE_SHIPPING_REQUEST:
      return state.set('zipLoading', true);
    case types.UPDATE_ZIP_FAILURE:
      return state.set('zipLoading', false);
    case types.UPDATE_ZIP_SUCCESS: {
      const count = action.ok ? action.payload.product_count.count : state.get('count');
      const cart = action.ok ? action.payload : {};
      const zip = action.ok ? action.payload.zip : ''; 
      return state.mergeDeep({
        count,
        zip,
        loading: false,
        productLoading: false,
        selectedProduct: 0,
        zipLoading: false,
        ...cart,
      }).set('shipping', Immutable.fromJS(action.payload.shipping)).set('messages', Immutable.fromJS(action.payload.messages));
    }
    case types.UPDATE_CART_SUCCESS:
    case types.UPDATE_SHIPPING_SUCCESS:
      // If this edited product is on the Removed list, take it off that list
      removedProducts = state.get('removed').filter(product => {
        return product.get('product_id') != action.product;
      });

      // For every removed product, insert placeholder row to show 'Removed' status
      removedProducts.forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        count: action.payload.product_count.count,
        loading: false,
        productLoading: false,
        selectedProduct: 0,
        removed: removedProducts,
        zipLoading: false,
        ...action.payload
      });
    case types.REMOVE_ITEM_SUCCESS:
      // Add this product to running tally of removed products
      removedProducts = state.get('removed').push(action.product.set('index', action.index));

      // For every removed product, insert placeholder row to show 'Removed' status
      removedProducts.forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        count: action.payload.product_count.count,
        loading: false,
        productLoading: false,
        selectedProduct: 0,
        removed: removedProducts,
        zipLoading: false,
        ...action.payload
      });
    case types.UNDO_DELETE_SUCCESS:
      // For every removed product, insert placeholder row to show 'Removed' status
      state.get('removed').forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        count: action.payload.product_count.count,
        loading: false,
        productLoading: false,
        selectedProduct: 0,
        removed: state.get('removed'),
        zipLoading: false,
        ...action.payload
      });
    case types.CLEAR_REMOVED_PRODUCTS:
      if (state.get('products')) {
        // build new list of products without any that were removed
        const newProducts = state.get('products').reduce((carry, product) => {
          // check all removed products and if this product is not in there, keep it
          const shouldRemoveProduct = state.get('removed').reduce((acc, removed) => {
            if (acc === true) {
              return true;
            }
            return removed.get('id') === product.get('id');
          }, false);

          if (!shouldRemoveProduct) {
            return carry.push(product);
          } else {
            return carry;
          }
        }, Immutable.List([]));

        return state.set('removed', Immutable.List([]))
                    .set('products', newProducts);
      } else {
        return state;
      }
    case types.GET_COUPON_FAILURE:
      if (action.payload && action.payload.error) {
        return state.set('invalidCoupon', action.payload.message);
      }
      return state;
    case types.GET_COUPON_SUCCESS:
      if (action.payload.error) {
        return state.set('invalidCoupon', action.payload.message);
      }  else {
        
        return state.set('invalidCoupon', false).mergeDeep(action.payload);
      } 
    case types.REMOVE_COUPON_SUCCESS:
      return  state.set('coupon', null).mergeDeep(action.payload);
    case types.ALERT_ACTION_SUCCESS:
      return initial.set('loading', false).mergeDeep(action.payload).set('count', action.payload.product_count.count);
    case checkoutTypes.SUBMIT_SHIPPING_REQUEST:
      return state.set('loading', true);
    case checkoutTypes.SUBMIT_SHIPPING_SUCCESS:
    case checkoutTypes.VALIDATE_ADDRESS_SUCCESS:
      // Every time we update shipping, cart pending order updates
      return state.set('loading', false).merge(action.payload.cart);
    case checkoutTypes.SUBMIT_BILLING_SUCCESS:
    case checkoutTypes.ORDER_SEARCH_SUCCESS:
    case checkoutTypes.CLEAR_PENDING_SUCCESS:
    case checkoutTypes.SET_WARRANTY_SUCCESS:
    case checkoutTypes.SET_PURCHASE_SITE_SUCCESS:
      return state.mergeDeep(action.payload);
    case checkoutTypes.SET_WARRANTY_FAILURE:
      return state.set('loading', false).set('custom_properties.warranty', false).set('custom_properties.serial_number', null).merge(action.payload.cart);
    case checkoutTypes.SET_USER_SUCCESS:
      return state.merge(action.payload);
    case types.SET_ADMIN_CHECKOUT_REQUEST:
      if (action.admin) {
        return state.set('user', 0);
      }
      return state;
    case types.SET_ADMIN_CHECKOUT_SUCCESS:
      return state.set('adminCheckout', Immutable.fromJS(action.payload.adminCheckout))
    case checkoutTypes.CLEAR_CHECKOUT:
      return initial;
    case types.CLEAR_ALERTS:
      return state.set('alerts', Immutable.List([]));
    case checkoutTypes.PLACE_ORDER_REQUEST:
      return state.set('loading', true);
    case checkoutTypes.PLACE_ORDER_SUCCESS:
    case checkoutTypes.PLACE_ORDER_FAILURE:
      return state.set('loading', false);
    case checkoutTypes.GET_ZIP_DATA_SUCCESS:
      // Entered in a new postal code, update display with city & state
      if (!state.get('pending')) {
        // If cart does not yet have a pending address, add it
        state = state.set('pending', Immutable.Map());
      }
      if (!state.getIn(['pending', 'shipping_address'])) {
        state = state.setIn(['pending', 'shipping_address'], Immutable.Map());
      }
      state = state.setIn(['pending', 'shipping_address', 'city'], action.payload.postal_code_city).setIn(['pending', 'shipping_address', 'state'], action.payload.postal_code_province_abv);
      return state;
    default:
      return state;
  }
}

export default reducer;

import React from 'react';
import classNames from 'classnames';
import AccountLinks from './AccountLinks';
import AdminMenu from './AdminMenu';
import { useAuth } from '@developers/single-sign-on';
import Link from 'next/link';
import type { Auth } from '../../types/Auth';
import { Nav } from '../../types/Nav';

const brandSections = [
  {
    title: 'A-D',
    letters: ['a', 'b', 'c', 'd'],
    brands: [],
  },
  {
    title: 'E-K',
    letters: ['e', 'f', 'g', 'h', 'i', 'j', 'k'],
    brands: [],
  },
  {
    title: 'L-R',
    letters: ['l', 'm', 'n', 'o', 'p', 'q', 'r'],
    brands: [],
  },
  {
    title: 'S-Z',
    letters: ['s', 't', 'u', 'v', 'w', 'x', 'y', 'z'],
    brands: [],
  },
];

const splitBrands = (brands) => {
  return brandSections.map(section => {
    section.brands = brands.filter((brand) => {
      const firstLetter = brand.label.charAt(0).toLowerCase();
      return section.letters.includes(firstLetter)
    });
    return section;
  });
};

const getCatalogMenu = (section) => {
  return (
    <li key={section.section}>
      <h4><a href={section.link}>{section.section}</a></h4>
      <ul>
        {section.items.map(item => (
          <li key={item.label}><a href={item.value}>{item.label}</a></li>
        ))}
      </ul>
    </li>
  );
}

interface Props {
  nav: Nav;
}

const Navigation = ({ nav }: Props) => {
  const auth = useAuth();

  const sections = splitBrands(nav.menus.menu_brands);

  return (
    <div className="row">
      <div className="collapse navbar-collapse" id="primary_nav">
        <ul className="nav navbar-nav navbar-right">
          <li className="nav_btn_main tsm_brands">
            <Link href="/">Home</Link>
          </li>
          <li id="menu_brands" className="nav_btn_main tsm_brands">
            <Link href="/brand">Brands</Link>
            <div className="nav_arrow"><div className="inner_arrow"></div></div>
            <ul id="brands_list">
              {sections.map(section => {
                return (
                  <li key={section.title}>
                    <h4>{section.title}</h4>
                    <ul>
                      {section.brands.map(brand => (
                        <li key={brand.value}><a href={brand.value}>{brand.label}</a></li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </li>
          <li id="menu_types" className="nav_btn_main tsm_clear">
            <a href="/type">Types</a>
            <div className="nav_arrow"><div className="inner_arrow"></div></div>
            <ul id="type_list">
              {nav.menus.types.map(type => (
                <li key={type.label}><a href={type.value}>{type.label}</a></li>
              ))}
            </ul>
          </li>
          <li id="menu_catalog" className={classNames('nav_btn_main', 'tsm_catalog', { 'admin_logged': auth.isAuthenticated && auth.session_data.admin })}>
            <a href="/catalog">Catalog</a>
            <div className="nav_arrow"><div className="inner_arrow"></div></div>
            <ul id="menu_catalog_sub">
              {
                auth.isAuthenticated && auth.session_data.admin && nav.menus.admin_catalog
                  ? nav.menus.admin_catalog.map(getCatalogMenu)
                  : nav.menus.catalog.map(getCatalogMenu)
              }
            </ul>
          </li>
          <li className="nav_btn_main tsm_clear">
            <Link href="/order-status">Orders and Returns</Link>
          </li>
          <li className="nav_btn_main tsm_clear">
            <a href="/ticket.php?action=kb">Help</a>
          </li>
          {auth.isAuthenticated && auth.session_data.admin ?
            <AdminMenu menus={nav.menus.admin_menu} />
            : null}
          <AccountLinks auth={auth as Auth} />
        </ul>
      </div>
    </div>
  );
}

export default Navigation;

import React from 'react';

interface Props {
  admin: boolean;
}

export default function AdminChat(props: Props) {
  if (props.admin) {
    return <div id="internal_chat_right" className="drop_menu_box"></div>
  }

  return null;
}

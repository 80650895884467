import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useAuth } from '@developers/single-sign-on';
import Email from './Email';
import Chat from './Chat';
import { usePermission } from '../../hooks/usePermission';
import { useLoginPrompt } from '../../hooks/LoginPromptContext';
import Link from 'next/link';

interface Props {
  small: boolean;
  chat:  string;
  cartCount: number;
}

export default function LoginButtons(props: Props) {
  const auth = useAuth();
  const { openPrompt } = useLoginPrompt();
  const canHideChatButton = usePermission('hide-customer-chat');

  const { small, cartCount } = props;
  const loginButtonStyle = small ? {} : { textAlign: 'right' as const, paddingRight: 0 };
  let cartText = cartCount > 1 ? ' (' + cartCount + ' Items)' : ' (1 Item)';
  cartText = cartCount <= 0 ? '' : cartText;

  if (auth.verifyMeta.loading || auth.loginMeta.loading) {
    return <Skeleton width={60} height={16} />
  }

  if (auth.isAuthenticated) {
    return (
      <div id="loginButtons" style={loginButtonStyle}>
        <Email auth={auth}>
          {!canHideChatButton &&
            <Chat chat={props.chat} />
          }
        </Email>
        <div className="top_btn" id="mainLogout" style={{ marginRight: '3px' }} onClick={() => auth.logout()}>
          <p>Log Out</p>
        </div>
        <div className="top_pipe">|</div>
        <div className="top_btn" id="cart_button"><Link href="/cart"><p>Cart{cartText}</p></Link></div>
      </div>
    );
  }

  return (
    <div id="loginButtons" style={loginButtonStyle}>
      <Email auth={auth}>
        {!canHideChatButton &&
          <Chat chat={props.chat} />
        }
      </Email>
      <div className="top_btn" style={{ marginRight: '3px' }} onClick={() => window.location.href = '/join.php'} title="Receive Volume Discounts: Become A Member">
        <p>Join</p>
      </div>
      <div className="top_pipe">
        |
      </div>
      <div className="top_btn" id="div_login" style={{ marginRight: '3px' }} onClick={openPrompt}>
        <p>Log In</p>
      </div>
      <div className="top_pipe">
        |
      </div>
      <div className="top_btn" id="cart_button">
        <Link href="/cart">
          <p style={{ marginRight: small ? '10px' : 0 }}>Cart {cartText}</p>
        </Link>
      </div>
    </div>
  );
}

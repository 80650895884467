import React from 'react';
import LoginButtons from './LoginButtons';
import Search from './Search';
import MobileSearch from './MobileSearch';
import Navigation from './Navigation';
import { useScreenSize } from '../../hooks/useScreenSize';
import * as navAjax from '../../ajax/nav';
import { useAjax } from '@developers/use-ajax';
import { useAuth } from '@developers/single-sign-on';
import type { Nav } from '../../types/Nav';
import Link from 'next/link';

const initialNav: Nav = {
  menus: {
    chat: '',
    brands: [],
    menu_brands: [],
    types: [],
    catalog: [],
    admin_catalog: [],
    admin_menu: {},
  }
};

export default function Header(props) {
  const auth = useAuth();
  const [nav, getNavMenus] = useAjax(navAjax.getNavMenus, initialNav);
  const { breakAt } = useScreenSize();

  React.useEffect(() => {
    getNavMenus();
  }, [getNavMenus, auth.isAuthenticated]);

  const { show, cartCount } = props;
  if (!show) {
    return null;
  }

  const small = !breakAt('sm');

  return (
    <div id="topContainer">
      <div className="container-fluid" id="topSectionTop">
        <div className="container topWrapper">
          <div className="row" id="topSectionTopRow">
            <div className="col-md-4" id="topLeftSection">
              <Link href="/"><a><img src="/images/site-logo2.png" id="topLogo" title="FitnessRepairParts.com Home Page" alt="FitnessRepairParts.com" /></a></Link>
            </div>
            <div className="col-md-8" id="topRightSection" style={{ textAlign: 'right' }}>
              <div className="row" id="topButtons" style={{ textAlign: 'right' }}>
                <LoginButtons
                  small={small}
                  chat={nav.menus.chat}
                  cartCount={cartCount}
                />
              </div>
              <div className="row hidden-xs" id="searchBarContainer">
                <div className="col-xs-12" id="topSearchBar">
                  <Search brands={nav.menus.brands} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="topSectionBottom" className="container-fluid">
        <div className="container topWrapper">
          <div className="row" id="topSectionBottomRow">
            <nav className="navbar navbar-default navbar-static-top navbarOverwrites">
              <div className="container-fluid">
                <MobileSearch />
                <Navigation nav={nav} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import types from './types';

export default function servicers(state = {data: [], foundServicers: true}, action) {
    
    switch (action.type) {
      case types.GET_SERVICERS_BY_ZIP_SUCCESS:
        return {data: action.payload, hasDistance: true, foundServicers: true}
      default:
        return state;
    }
  }
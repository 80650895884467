import { url_prefix as prefix } from '../config';
import types from './types';

const usePaymentProfiles = true;

/**
 * Used to submit whole page
 */
export function getShippingAjax(values, skip = false, address_id = '') {
  values = values.set('skip', skip);
  return {
    types: [
      types.SUBMIT_SHIPPING_REQUEST,
      types.SUBMIT_SHIPPING_SUCCESS,
      types.SUBMIT_SHIPPING_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/submit/shipping',
      method: 'POST',
      data: values.toJS()
    },
    skip,
    address_id,
  };
};

/**
 * Used for validation
 */
export function updateShippingAddress(values, skip = false) {
  values = values.set('skip', skip);
  return {
    types: [
      types.VALIDATE_ADDRESS_REQUEST,
      types.VALIDATE_ADDRESS_SUCCESS,
      types.VALIDATE_ADDRESS_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/submit/shipping',
      method: 'POST',
      data: values.toJS()
    },
    skip
  };
};

export function getAddresses(id) {
  if (!id) {
    return { type: types.GET_ADDRESSES_FAILURE };
  }
  return {
    types: [
      types.GET_ADDRESSES_REQUEST,
      types.GET_ADDRESSES_SUCCESS,
      types.GET_ADDRESSES_FAILURE
    ],
    ajax: {
      url: prefix + '/users/' + id,
      method: 'GET',
      data: {
        with: 'account.users',
        attributes: 'shippingAddresses,cardList',
      }
    }
  };
}

export function submitBillingAjax(values) {
  return {
    types: [
      types.SUBMIT_BILLING_REQUEST,
      types.SUBMIT_BILLING_SUCCESS,
      types.SUBMIT_BILLING_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/submit/billing',
      method: 'POST',
      data: values.toJS()
    }
  };
}

export function getCart() {
  return {
    types: [
      types.GET_CART_REQUEST,
      types.GET_CART_SUCCESS,
      types.GET_CART_FAILURE
    ],
    ajax: {
      url: prefix + '/cart'
    }
  };
}

export function clearPending() {
  return {
    types: [
      types.CLEAR_PENDING_REQUEST,
      types.CLEAR_PENDING_SUCCESS,
      types.CLEAR_PENDING_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/clear-pending'
    }
  };
}

export function updateCreditCard(values) {
  return {
    type: types.UPDATE_CREDIT_CARD,
    payload: values
  };
};

export function updateShipping(code) {
  return {
    types: [
      types.UPDATE_SHIPPING_REQUEST,
      types.UPDATE_SHIPPING_SUCCESS,
      types.UPDATE_SHIPPING_FAILURE
    ],
    ajax: {
      type: 'POST',
      url: prefix + '/cart/update/shipping/' + code
    }
  };
}

export function updateCart(productNumber, qty, index = false) {
  return {
    types: [
      types.UPDATE_CART_REQUEST,
      types.UPDATE_CART_SUCCESS,
      types.UPDATE_CART_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/set/' + productNumber,
      data: { qty, index }
    },
    product: productNumber
  };
}

export function removeItem(product, index) {
  return {
    types: [
      types.REMOVE_ITEM_REQUEST,
      types.REMOVE_ITEM_SUCCESS,
      types.REMOVE_ITEM_FAILURE
    ],
    ajax: {
      url: prefix + '/cart/remove/' + product.get('product_id'),
    },
    product,
    index
  };
}

export function toggleReviewPolicy(value) {
  return {
    type: types.SET_POLICY,
    payload: value
  };
}

export function setUser(value, updateShipping = true) {
  return {
    types: [
      types.SET_USER_REQUEST,
      types.SET_USER_SUCCESS,
      types.SET_USER_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/checkout/set-user',
      data: { asUser: value, updateShipping }
    }
  };
}

export function setPurchaseTakenBy(email) {
  return {
    type: types.SET_PURCHASE_TAKEN_BY,
    payload: email
  }
}

export function touchPolicy() {
  return {
    type: types.TOUCH_POLICY
  }
}

export function placeOrderAjax(values, extraInfo = '') {
  const couponDiscount = extraInfo.coupon_discount ? extraInfo.coupon_discount : '';
  return {
    types: [
      types.PLACE_ORDER_REQUEST,
      types.PLACE_ORDER_SUCCESS,
      types.PLACE_ORDER_FAILURE
    ],
    ajax: {
      method: 'POST',
      url: prefix + '/checkout/submit/order',
      data: { ...values.toJS(), extraInfo }
    },
    couponDiscount
  };
}

export function clearCheckout() {
  return {
    type: types.CLEAR_CHECKOUT,
  };
}

export function getReturnPolicy() {
  return {
    types: [
      types.GET_RETURN_POLICY_REQUEST,
      types.GET_RETURN_POLICY_SUCCESS,
      types.GET_RETURN_POLICY_FAILURE
    ],
    ajax: {
      url: prefix + '/company/return-policy',
    }
  };
}

export function getCardInfo(id) {
  // Do not use saved cards at all if not using payment profiles
  if (!usePaymentProfiles) {
    return {
      type: types.GET_CARD_INFO_FAILURE,
    };
  }
  return {
    types: [
      types.GET_CARD_INFO_REQUEST,
      types.GET_CARD_INFO_SUCCESS,
      types.GET_CARD_INFO_FAILURE
    ],
    ajax: {
      url: prefix + '/user-cards',
      data: {
        card_id: id,
      }
    }
  };
}

export function setAdminCheckoutType(order_type, purchase_site) {
  return {
    type: types.UPDATE_ORDER_TYPE,
    payload: { order_type, purchase_site }
  }
}

export function setPurchaseSite(site) {
  return {
    types: [
      types.SET_PURCHASE_SITE_REQUEST,
      types.SET_PURCHASE_SITE_SUCCESS,
      types.SET_PURCHASE_SITE_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/purchase_site',
      data: {
        purchase_site: site,
      }
    }
  };
}

export function setAdminCheckoutAccount(data) {
  return {
    type: types.UPDATE_ACCOUNT_NUMBER,
    payload: data
  }
}

export function setAddressBlock(data) {
  return {
    type: types.SET_ADDRESS_BLOCK,
    payload: data
  }
}

/**
 * 
 * Sets to zero. Seems like it could just proxy to another method, without a custom type being needed.
 */
export function resetStep() {
  return {
    type: types.RESET_STEP
  }
}

/**
 * 
 * Called from shipping and billing submit. Don't know why....
 */
export function lockStep(step) {
  return {
    type: types.LOCK_STEP,
    payload: step
  }
}

export function setSendCheck(data) {
  return {
    type: types.SET_SEND_CHECK,
    payload: data
  }
}

export function setPreviousOrderNumber(order) {
  return {
    type: types.SET_PREVIOUS_ORDER_NUMBER,
    payload: order
  }
}

export function orderSearch(number) {
  return {
    types: [
      types.ORDER_SEARCH_REQUEST,
      types.ORDER_SEARCH_SUCCESS,
      types.ORDER_SEARCH_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/order-search/' + number
    }
  };
}

export function accountSearch(number) {
  return {
    types: [
      types.ACCOUNT_SEARCH_REQUEST,
      types.ACCOUNT_SEARCH_SUCCESS,
      types.ACCOUNT_SEARCH_FAILURE
    ],
    ajax: {
      url: prefix + '/accounts/' + number,
      data: {
        with: 'users'
      }
    }
  };
}

export function getZipData(code) {
  return {
    types: [
      types.GET_ZIP_DATA_REQUEST,
      types.GET_ZIP_DATA_SUCCESS,
      types.GET_ZIP_DATA_FAILURE
    ],
    ajax: {
      url: prefix + '/postal-code/' + code
    }
  };
}

/**
 * 
 * Sets the step on the pending order, and then updates the reducer
 */
export function changeStep(step = 0) {
  return {
    types: [
      types.CHANGE_STEP_REQUEST,
      types.CHANGE_STEP,
      types.CHANGE_STEP_FAILURE
    ],
    ajax: {
      url: prefix + '/checkout/update-step/' + step,
      method: 'POST',
    }
  };
};

export function setWarrantyAjax(warranty, serial_number = null, claim_reason = null) {
  return {
    types: [
      types.SET_WARRANTY_REQUEST,
      types.SET_WARRANTY_SUCCESS,
      types.SET_WARRANTY_FAILURE
    ],
    ajax: {
      method: 'post',
      url: prefix + '/checkout/warranty',
      data: {
        warranty: warranty,
        serial_number: serial_number,
        claim_reason: claim_reason
      }
    }
  };
}

export function setSurchargeRequested(value) {
  return {
    type: 'SET_SURCHARGE_REQUESTED',
    payload: { value }
  };
}

/**
 * Sets the step in the reducer, but does not update the pending order like changeStep
 */
export function overwriteValidStep(value) {
  return {
    type: types.OVERWRITE_VALID_STEP,
    payload: value,
  };
}

export function setCheckoutLoading(value) {
  return {
    type: types.SET_CHECKOUT_LOADING,
    payload: value,
  };
}

export function setErrorPopup(message, problemPage = 'review') {
  return {
    type: types.SET_ERROR_POPUP,
    payload: {
      message,
      problemPage,
    }
  }
}

export function resetErrorPopup() {
  return {
    type: types.RESET_ERROR_POPUP,
  }
}

export function setAVSPopup(message) {
  return {
    type: types.SET_AVS_POPUP,
    payload: {
      message,
    }
  }
}

export function resetAVSPopup() {
  return {
    type: types.RESET_AVS_POPUP,
  }
}
import React from 'react';

const Email = ({ auth, children }) => {
  return (
    <div className="hidden-xs" id="loginText">
      <EmailText auth={auth} />
      {children}
    </div>
  );
};

const EmailText = ({ auth }) => {
  if (auth.isAuthenticated) {
    const userId = auth.session_data.admin ? '[' + auth.session_data.user?.user_id + ']' : '';
    
    return (<p>Logged In As: {auth.session_data.user?.email} {userId}</p>);
  }

  return null;
};

export default Email;

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

interface Props {
  show: boolean;
  time_left: number;
  handleClose: () => void;
}

export default function WarningMessage(props: Props) {
  return (
    <Dialog open={props.show}>
      <DialogContent>
      You will be logged out in {props.time_left} {props.time_left > 1 ? 'minutes' : 'minute'} due to inactivity.
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary" autoFocus={true}>I&lsquo;m Still Active!</Button>
      </DialogActions>
    </Dialog>
  );
}
import * as actions from './actions';

export function getLocationAndPrint(id) {
  return function (dispatch, getState) {
    dispatch(actions.getLocation(id)).then(()=> {
      window.print();
    })
  }
}

export default {
  getLocationAndPrint,
  ...actions,
};

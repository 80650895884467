import React from 'react';

interface Props {
  admin: boolean;
  hide: boolean;
}

function WebsiteRequest({ admin, hide }: Props) {
  const alreadySubmitting = typeof window !== 'undefined' && window.location.pathname == '/reports/it/request';

  function handleClick(e) {
    e.preventDefault();
    window.open('/reports/it/request?page=' + window.location.href, 'newwindow', 'width=1200,height=800');
  }

  if (hide) {
    return null;
  }

  if (admin && !alreadySubmitting) {
    return (
      <div style={{ clear: 'both' }}>
        <p style={{ textAlign: 'center' }}>
          <a href="#" onClick={() => {window.scrollTo(0, 0);}}>Go To Top</a>
          <br />
          <a target="_blank" rel="noreferrer" href={'/reports/it/request?page=' + window.location.href} onClick={handleClick}>Submit Website Request</a>
        </p>
        <div id="submit_website_request" style={{ width: '100%' }}></div>
      </div>
    );
  }

  return null;
}

export default WebsiteRequest;

import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import types from './types';

function errorPopup(state = {open: false, message: '', problemPage: null}, action) {
  switch (action.type) {
    case types.SET_ERROR_POPUP:
      return {
        open: true,
        message: action.payload.message,
        problemPage: action.payload.problemPage,
      };
      case types.RESET_ERROR_POPUP:
        return {
          open: false,
          message: '',
          problemPage: '',
        };
    default:
      return state;
  }
}

function avsPopup(state = {open: false, message: ''}, action) {
  switch (action.type) {
    case types.SET_AVS_POPUP:
      return {
        open: true,
        message: action.payload.message,
      };
      case types.RESET_AVS_POPUP:
        return {
          open: false,
          message: '',
        };
    default:
      return state;
  }
}

function validStep(state = 0, action) {
  switch (action.type) {
    case types.CHANGE_STEP:
      // Only change step if bigger, cannot go backwards in valid step
      return action.payload.step < state ? state : action.payload.step;
    case types.LOCK_STEP:
      return action.payload;
    case types.RESET_STEP:
    case types.CLEAR_PENDING_SUCCESS:
      return 0;
    case types.OVERWRITE_VALID_STEP:
      return action.payload;
    default:
      return state;
  }
}

function loading(state = true, action) {
  switch (action.type) {
    case types.SET_CHECKOUT_LOADING:
      return action.payload;
    default:
      return state;
  }
}

const initialAdminCheckout = Immutable.Map({
  order_type: 'phone', 
  accountNumber: 0, 
  purchase_taken_by: '',
  asUser: 0,
  address_block: '',
  sendCheck: false,
  warranty_notes: '',
  warranty_purchase_date: '',
  previous_orders: Immutable.List(),
  loading: false,
  surcharge_requested_by: '',
})

function adminCheckout(state = initialAdminCheckout, action) {
  switch (action.type) {
    case types.GET_ADDRESSES_SUCCESS:
      return state.set('accountNumber', action.payload.account_id).set('asUser', action.payload.user_id);
    case types.UPDATE_ORDER_TYPE:
      return state.set('order_type', Immutable.fromJS(action.payload.order_type));
    case types.UPDATE_ACCOUNT_NUMBER:
      return state.set('accountNumber', Immutable.fromJS(action.payload));
    case types.SET_PREVIOUS_ORDER_NUMBER:
      return state.set('previousOrder', Immutable.fromJS(action.payload));
    case types.SET_PURCHASE_TAKEN_BY:
      return state.set('purchase_taken_by', Immutable.fromJS(action.payload));
    case types.SET_USER_SUCCESS:
      return state.set('asUser', Immutable.fromJS(action.payload.user));
    case types.SET_ADDRESS_BLOCK:
      return state.set('address_block', action.payload);
    case types.SET_SEND_CHECK:
      return state.set('sendCheck', !state.get('sendCheck'));
    case types.SET_WARRANTY_REQUEST:
      return state.set('loading', true).set('warranty_error', null).set('warranty_notes', '').set('warranty_purchase_date', '');
    case types.SET_WARRANTY_FAILURE:
      return state.set('loading', false).set('warranty_error', Immutable.fromJS(action.payload.error)).set('warranty_notes', '').set('warranty_purchase_date', '').set('previous_orders', Immutable.List());
    case types.SET_WARRANTY_SUCCESS:
      return (
        state.set('warranty_error', null).set('loading', false)
        .set('warranty_notes', Immutable.fromJS(action.payload.warranty_notes))
        .set('warranty_purchase_date', Immutable.fromJS(action.payload.purchase_date))
        .set('previous_orders', Immutable.fromJS(action.payload.previous_orders))
      );
    case types.SET_SURCHARGE_REQUESTED:
      return state.set('surcharge_requested_by', action.payload.value);
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialAdminCheckout;
    default:
      return state;
  }
}

function error(state = '', action) {
  switch (action.type) {
    case types.PLACE_ORDER_SUCCESS:
      if (action.payload.error) {
        // if message exists assume we handle it elseware
        const errorType = action.payload.message;
        const billingErrors = ['cc-number', 'cc-csc', 'cc-date', 'No Billing Address', 'no selected shipping'];

        // if (billingErrors.indexOf(errorType) !== -1) {
        if (action.payload.message && action.payload.message !== 'order-exists') {
          return '';
        } else {
          return 'There was an error processing this order and it was NOT placed. Please clear the cookies in your browser or use a different Internet browser and try to place the order again. Thank you.';
        }
      }

      return '';
    case types.PLACE_ORDER_FAILURE:
      return 'There was an error processing this order and it was NOT placed. Please clear the cookies in your browser or use a different Internet browser and try to place the order again. Thank you.';
    case types.CLEAR_CHECKOUT:
      return '';
    default:
      return state;
  }
}

const initialShipping = Immutable.fromJS({
  loading: false,
  validation: {}
});

function shipping(state = initialShipping, action) {
  switch (action.type) {
    case types.GET_ZIP_DATA_REQUEST:
      return state.set('loading', true);
    case types.GET_ZIP_DATA_SUCCESS:
    case types.GET_ZIP_DATA_FAILURE:
      return state.set('loading', false);
    case types.SUBMIT_SHIPPING_REQUEST:
    case types.VALIDATE_ADDRESS_REQUEST:
      if (action.skip) {
        return state;
      }
      return state.set('loading', true);
    case types.SUBMIT_SHIPPING_SUCCESS:
    case types.VALIDATE_ADDRESS_SUCCESS:
      return Immutable.fromJS({
        loading: false,
        validation: action.payload
      });
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialShipping;
    default:
      return state;
  }
}

const initialBilling = Immutable.fromJS({
  loading: false,
  error: { exists: false, type: '' }
});

function billing(state = initialBilling, action) {
  switch (action.type) {
    case types.SUBMIT_BILLING_REQUEST:
    case types.GET_CARD_INFO_REQUEST:
      return state.set('loading', true);
    case types.SUBMIT_BILLING_SUCCESS:
    case types.GET_CARD_INFO_SUCCESS:
    case types.GET_CARD_INFO_FAILURE:
      return state.set('loading', false);
    case types.PLACE_ORDER_SUCCESS:
      if (action.payload.error) {
        return state.set('error', Immutable.Map({ exists: true, type: action.payload.message }));
      }
      return state;
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialBilling;
    default:
      return state;
  }
}

const initialAddresses = Immutable.fromJS({
  loading: true,
  address_id: '',
  addresses: [],
  shipPrivs: '',
  cardList: [],
  email: ''
});

function addresses(state = initialAddresses, action) {
  switch (action.type) {
    case types.SUBMIT_SHIPPING_SUCCESS:
      if (action.address_id !== '') {
        return state.set('address_id', action.address_id);
      }
      return state;
    case types.GET_ADDRESSES_REQUEST:
      return state.set('loading', true);
    case types.GET_ADDRESSES_SUCCESS:
      return Immutable.fromJS({
        loading: false,
        addresses: action.payload.shippingAddresses,
        shipPrivs: action.payload.ship_privs,
        cardList: action.payload.cardList,
        email: action.payload.email
      });
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialAddresses;
    default:
      return state;
  }
}

const initialOrderState = Immutable.Map({
  'coupon_discount': '',
  'loading': false
});
function order(state = initialOrderState, action) {
  switch (action.type) {
    case types.PLACE_ORDER_REQUEST:
      return state.set('loading', true);
    case types.PLACE_ORDER_SUCCESS: {
      let order = Immutable.fromJS(action.payload);
      order = order.set('loading', state.get('loading'));
      if (action.couponDiscount) {
        order = order.set('coupon_discount', action.couponDiscount);
      }
      return order;
    }
    case 'CLEAR_CHECKOUT':
      return state.set('loading', false);
    default:
      return state;
  }
}

const initialPrevOrder = Immutable.Map({
  error: false,
});

function prevOrder(state = initialPrevOrder, action) {
  switch (action.type) {
    case types.ORDER_SEARCH_FAILURE:
      return Immutable.Map({ error: true });
    case types.ORDER_SEARCH_SUCCESS:
      return Immutable.fromJS({
        error: false,
        account: action.payload.account_id,
        shipping: {
          addr1: action.payload.purchase_ship_address_1,
          addr2: action.payload.purchase_ship_address_2,
          name: action.payload.purchase_ship_contact,
          company: action.payload.purchase_ship_company,
          city: action.payload.purchase_ship_city,
          state: action.payload.shipping_state.state_abbreviation,
          postal_code: action.payload.purchase_ship_zip,
          phone: action.payload.purchase_phone,
          email: action.payload.purchase_email
        },
        billing: {
          addr1: action.payload.purchase_card_address_1,
          addr2: action.payload.purchase_card_address_2,
          company: action.payload.purchase_card_company,
          city: action.payload.purchase_card_city,
          state: action.payload.billing_state ? action.payload.billing_state.state_abbreviation : '',
          postal_code: action.payload.purchase_card_zip,
        }
        });
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialPrevOrder;
    default:
      return state;
  }
}

function zipData(state = Immutable.Map({}), action) {
  switch (action.type) {
    case types.GET_ZIP_DATA_SUCCESS:
      if (action.payload) {
        return Immutable.fromJS({
          city: action.payload.postal_code_city,
          state: action.payload.postal_code_province_abv
        });
      }
      return state;
    case types.CLEAR_CHECKOUT:
      return Immutable.Map({});
    default:
      return state;
  }
}

function prevAccount(state = Immutable.fromJS({ users: [] }), action) {
  switch (action.type) {
    case types.GET_ADDRESSES_SUCCESS:
      return Immutable.fromJS({
        account_id: action.payload.account_id,
        primary_admin_user_id: action.payload.user_id,
        terms: action.payload.account?.terms,
        credit_terms: action.payload.account?.credit_terms,
        users: action.payload.account?.users
      });
    case types.ACCOUNT_SEARCH_SUCCESS:
      return Immutable.fromJS(action.payload);
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return Immutable.fromJS({ users: [] });
    default:
      return state;
  }
}

const initialProducts = Immutable.fromJS({
  loading: false,
  selectedProduct: 0,
  removed: [],
  products: []
});

function products(state = initialProducts, action) {
  let removedProducts;
  switch (action.type) {
    
    case types.GET_CART_SUCCESS:
    case types.REWRITE_CART_SUCCESS:
    case types.SUBMIT_BILLING_SUCCESS:
    case types.UPDATE_SHIPPING_SUCCESS:
      return state.set('products', Immutable.fromJS(action.payload.products));
    case types.SUBMIT_SHIPPING_SUCCESS:
      return state.set('products', Immutable.fromJS(action.payload.cart.products));
    case types.UPDATE_CART_REQUEST:
    case types.REMOVE_ITEM_REQUEST:
      return state.set('loading', true).set('selectedProduct', action.product);
    case types.UPDATE_CART_SUCCESS:
      // If this edited product is on the Removed list, take it off that list
      removedProducts = state.get('removed').filter(product => {
        return product.get('number') != action.product;
      });

      // For every removed product, insert placeholder row to show 'Removed' status
      removedProducts.forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        loading: false,
        selectedProduct: 0,
        removed: removedProducts,
        products: action.payload.products,
      });
    case types.REMOVE_ITEM_SUCCESS:
      // Add this product to running tally of removed products
      removedProducts = state.get('removed').push(action.product.set('index', action.index));

      // For every removed product, insert placeholder row to show 'Removed' status
      removedProducts.forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        loading: false,
        selectedProduct: 0,
        removed: removedProducts,
        products: action.payload.products,
      });
    case types.UNDO_DELETE_SUCCESS:
      // For every removed product, insert placeholder row to show 'Removed' status
      state.get('removed').forEach(product => {
        action.payload.products.splice(product.get('index'), 0, product.set('removed', true));
      });

      return Immutable.fromJS({
        loading: false,
        selectedProduct: 0,
        removed: state.get('removed'),
        products: action.payload.products,
      });
    case types.CLEAR_CHECKOUT:
      return initialProducts;
    default:
      return state;
  }
};

const initialCreditCard = Immutable.Map({
  'cc-number': '',
  'cc-csc': '',
  'cc-name': '',
  'cc-exp-month': '',
  'cc-exp-year': '',
  'organization': '',
  'addr1': '',
  'addr2': '',
  'city': '',
  'state': '',
  'zip': '',
});

function creditCard(state = initialCreditCard, action) {
  switch (action.type) {
    case types.UPDATE_CREDIT_CARD:
      return action.payload;
    case types.CLEAR_CHECKOUT:
      return initialCreditCard;
    case types.GET_CARD_INFO_SUCCESS: {
      const cardInfo = action.payload.card_info;
      return Immutable.Map({
        'cc-number': cardInfo.card_number,
        'cc-csc': '',
        'cc-name': cardInfo.user_bin_name,
        'cc-exp-month': cardInfo.card_exp_month,
        'cc-exp-year': cardInfo.card_exp_year,
        'organization': cardInfo.user_bin_company,
        'addr1': cardInfo.user_bin_address_1,
        'addr2': cardInfo.user_bin_address_2,
        'city': cardInfo.user_bin_city,
        'postal_code': cardInfo.user_bin_zip,
        'state': cardInfo.user_bin_state_id,
        'credit_card_id': cardInfo.user_bin_id
      });
    }
    case types.GET_CARD_INFO_FAILURE:
    case types.CLEAR_PENDING_SUCCESS:
      return initialCreditCard;
    case types.GET_PREVIOUS_CARD_SUCCESS:
      if (!action.payload.number) {
        return state;
      }
      return Immutable.Map({
        'cc-number': action.payload.number,
        'cc-name': action.payload.name,
        'cc-exp-month': action.payload.month,
        'cc-exp-year': action.payload.year,
        'organization': action.payload.organization,
      });
    default:
      return state;
  }
};

const initialShippingOptions = Immutable.Map({
  loading: false
});

function shippingOptions(state = initialShippingOptions, action) {
  switch (action.type) {
    case types.UPDATE_SHIPPING_REQUEST:
      return state.set('loading', true);
    case types.UPDATE_SHIPPING_SUCCESS:
      return state.set('loading', false);
    case types.CLEAR_CHECKOUT:
    case types.CLEAR_PENDING_SUCCESS:
      return initialShippingOptions;
    default:
      return state;
  }
}

const initialPolicy = Immutable.Map({
  checked: false,
  touched: false,
  policy: '',
});

function returnPolicy(state = initialPolicy, action) {
  switch (action.type) {
    case types.GET_RETURN_POLICY_SUCCESS:
      return state.set('policy', action.payload.policy);
    case types.SET_POLICY:
      return state.set('touched', true).set('checked', action.payload ? true : false);
    case types.TOUCH_POLICY:
      return state.set('touched', true);
    case types.CLEAR_CHECKOUT:
      return state.set('checked', false).set('touched', false);
    default:
      return state;
  }
}

const reducer = combineReducers({
  validStep,
  loading,
  error,
  shipping,
  billing,
  order,
  products,
  creditCard,
  shippingOptions,
  returnPolicy,
  addresses,
  prevAccount,
  adminCheckout,
  zipData,
  errorPopup,
  avsPopup,
});

export default reducer;

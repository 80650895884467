import { url_prefix as prefix } from '../config';
import types from './types';

export function searchAccounts(keywords: string, page = 1) {
  return {
    types: [
      types.GET_ACCOUNTS_REQUEST,
      types.GET_ACCOUNTS_SUCCESS,
      types.GET_ACCOUNTS_FAILURE
    ],
    ajax: {
      url: prefix + '/accounts/search',
        data: {
          q: keywords,
          page
        }
    }
  };
};

export function clearSearch() {
  return {
    type: types.CLEAR_SEARCH,
  };
};
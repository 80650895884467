import * as actions from './actions';

const removeSynonymAndGetTerm = (synonym_id, term_id) => {
  return function (dispatch) {
    dispatch(actions.detachSynonym(synonym_id, term_id)).then(() => {
      dispatch(actions.getTerm(term_id));
    });
  }
}

const addSynonymAndGetTerm = (synonym, id) => {
  return function (dispatch) {
    dispatch(actions.createSynonym(synonym, id)).then(() => {
      dispatch(actions.getTerm(id));
    });
  }
}

const deleteTermAndUpdateList = (id) => {
  return function(dispatch) {
    dispatch(actions.deleteTerm(id)).then(() => {
      dispatch(actions.getTerms());
      });
  }
}

const createTermAndUpdateList = (term, definition) => {
  return function(dispatch) {
    dispatch(actions.createTerm(term, definition)).then(() => {
      dispatch(actions.getTerms());
      });
  }
}


export default {
  ...actions,
  removeSynonymAndGetTerm,
  addSynonymAndGetTerm,
  deleteTermAndUpdateList,
  createTermAndUpdateList
};


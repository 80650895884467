import { ThemeOptions } from '@mui/material/styles';

const defaultTextColor = '#000';
const theme: ThemeOptions = {
  palette: {
    primary: {
      main: '#046cc1',
    },
    secondary: {
      main: '#e00909',
    }
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 16,
    body1: {
      fontSize: '14px',
      color: defaultTextColor,
    },
    h1: {
      textTransform: 'uppercase',
      fontWeight: 700,
      fontStyle: 'italic',
      fontSize: '31px',
      color: defaultTextColor,
    },
    h2: {
      fontSize: '28px',
      fontWeight: 500,
      color: defaultTextColor,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 500,
      color: defaultTextColor,
    },
    h4: {
      fontSize: '18px',
      fontWeight: 500,
      color: defaultTextColor,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 500,
      color: defaultTextColor,
    },
    h6: {
      fontSize: '20px',
      fontWeight: 500,
      color: defaultTextColor,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
  },
};

export default theme;

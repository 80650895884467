import React from 'react';
import { useAuth } from '@developers/single-sign-on';
import { getTimeToExpiration, resetExpirationTimer } from '../../ajax/auth';
import WarningMessage from './WarningMessage';
import debounce from '@developers/utils/debounce';

export default function LogoutWarning() {
  const auth = useAuth();
  const [time_remaining, setTimeRemaining] = React.useState(15);

  React.useEffect(() => {
    let interval: null | NodeJS.Timeout = null;
    
    // Throttle reset attempts to 1 per 10 seconds
    const debouncedAttemptReset = debounce(attemptReset, 5000);

    if (auth.isAuthenticated && auth.session_data.admin) {
      // Handle reset if login state has changed (user timed out and re-logged in)
      setTimeRemaining(15);
      
      // Attempt reset on any click event
      window.addEventListener('click', debouncedAttemptReset);

      // Check expiration every 1 minute
      interval = setInterval(async () => {
          const response = await getTimeToExpiration();
          const result = await response.json();

          if (result.auth) {
            setTimeRemaining(result.remaining);
          } else {
            setTimeRemaining(0);
            auth.logout(true);
            clearInterval(interval);
          }
      }, 60000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        window.removeEventListener('click', debouncedAttemptReset);
      }
    }
  }, [auth.isAuthenticated]);

  async function attemptReset() {
    const response = await resetExpirationTimer();
    const result = await response.json();

    if (result.refreshed) {
      setTimeRemaining(15);
    } else {
      auth.logout(true);
    }
  }

  return (
    <>
      <WarningMessage 
        show={auth.isAuthenticated && time_remaining <= 5} 
        time_left={time_remaining} 
        handleClose={attemptReset}
      />
    </>
  );
}

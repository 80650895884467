import React from 'react';

interface Props {
  full?: boolean;
  children: React.ReactElement | React.ReactNode;
}

export default function Body(props: Props) {
  const containerId = props.full ? 'body_container' : 'body_condensed';

  return (
    <div id={containerId}>
      <div id="body_content">
        <div className="container" id="content-area">
          {props.children}
        </div>
      </div>
    </div>
  );
}

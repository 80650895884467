import { url_prefix as prefix } from '../config';
import types from './types';

export function getProduct(id) {
  return {
    types: [
      types.GET_PRODUCT_REQUEST,
      types.GET_PRODUCT_SUCCESS,
      types.GET_PRODUCT_FAILURE
    ],
    ajax: {
      url: prefix + '/products/' + id
    }
  };
};

export function getManufacturers() {
  return {
    types: [
      types.GET_MANUFACTURERS_REQUEST,
      types.GET_MANUFACTURERS_SUCCESS,
      types.GET_MANUFACTURERS_FAILURE
    ],
    ajax: {
      url: prefix + '/manufacturers',
      data: {paginate: 'no'}
    }
  }
}

export function createUpdateRequest(id, suggestedChanges, notes, email) {
  return {
    types: [
      types.CREATE_UPDATE_SUGGESTION_REQUEST,
      types.CREATE_UPDATE_SUGGESTION_SUCCESS,
      types.CREATE_UPDATE_SUGGESTION_FAILURE
    ],
    ajax: {
      url: prefix + '/products/' + id + '/create-update-request',
      method: 'POST',
      data: {suggestedChanges, notes, email}
    }
  };
};



import addNamespace from '../addNamespace';

const GET_STATES_REQUEST = 'GET_STATES_REQUEST';
const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export  default addNamespace({
  GET_STATES_REQUEST,
  GET_STATES_SUCCESS,
  GET_STATES_FAILURE,
}, 'states');

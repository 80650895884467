import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import types from './types';

const initialAccounts = Immutable.fromJS({
  total: 0,
  per_page: 100,
  current_page: 1,
  last_page: 1,
  from: 0,
  to: 0,
  data: []
})

function accounts(state = initialAccounts, action: {type: string; payload: any;}) {
  switch (action.type) {
    case types.GET_ACCOUNTS_SUCCESS:
      return state.merge(action.payload)
    case types.CLEAR_SEARCH:
      return initialAccounts;
    default:
      return state
  }
}

const reducer = combineReducers({
  accounts
});

export default reducer;

import { useAuth } from '@developers/single-sign-on';
import { hasPermission } from '../utils/hasPermission';

export function usePermission(permission_name: string): boolean {
  const auth = useAuth();

  if (!auth.isAuthenticated || !auth.session_data.user || !auth.session_data.user.permissions) {
    return false;
  }

  return hasPermission(auth.session_data.user.permissions, permission_name);
}
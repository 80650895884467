import addNamespace from '../addNamespace';

const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';


export default addNamespace({
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
}, 'account_search');
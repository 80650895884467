import { prefix, ajax } from './common';

export async function getBanners() {
  return await ajax({
    url: prefix + '/plugins/banners'
  });
}

export async function getActiveBanners(status = 'active') {
  return await ajax({
    url: prefix + '/plugins/banners',
    data: {
      status
    }
  });
}

export async function createBanner(bannerInfo) {
  return await ajax({
    method: 'POST',
    url: prefix + '/plugins/banners',
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status
    }
  });
}

export async function getBanner(id) {
  return await ajax({
    url: prefix + '/plugins/banners/' + id
  });
}

export async function editBanner(bannerInfo) {
  return await ajax({
    method: 'PUT',
    url: prefix + '/plugins/banners/' + bannerInfo.id,
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status
    }
  });
}

export async function deleteBanner(bannerInfo) {
  return await ajax({
    method: 'DELETE',
    url: prefix + '/plugins/banners/' + bannerInfo.id,
    data: {
      name: bannerInfo.name,
      message: bannerInfo.message,
      description: bannerInfo.description,
      status: bannerInfo.status
    }
  });
}

import React from 'react';
import { Visa, Mastercard, Discover } from '@developers/frp-library/Cards';
import Link from 'next/link';

interface Props {
  show: boolean;
}

export default function Footer(props: Props) {
  if (!props.show) {
    return null;
  }

  return (
    <div id="footer-area" className="container-fluid">
      <div id="footer_links" className="row">
        <div className="footer-column col-xs-6 col-sm-3" style={{ padding: 15 }}>
          <h1>Information</h1>
          <Link href="/">Home</Link>
          <a href="/join.php">Join</a>
          <a href="/part_search">Advanced Search</a>
          <Link href="/searchtips">Search Tips</Link>
          <Link href="/brand">Brands</Link>
          <a href="/catalog">Catalog</a>
          <a href="/specials.php">Specials</a>
          <Link href="/site-map">Site Map</Link>
        </div>
        <div className="footer-column col-xs-6 col-sm-3" style={{ padding: 15 }}>
          <h1>Customer Service</h1>
          <Link href="/order-status">Order Status</Link>
          <Link href="/support">Support</Link>
          <a href="/ticket.php?action=kb">FAQ</a>
          <a href="/glossary">Glossary of Terms</a>
          <Link href="/find-a-servicer">Find A Servicer</Link>
          <a href="/part_buyout.php">Sell Us Parts</a>
          <a href="/ticket.php?action=open">Contact Us</a>
        </div>
        <div className="clearfix visible-xs-block"></div>
        <div className="footer-column col-xs-6 col-sm-3" style={{ padding: 15 }}>
          <h1>About Us</h1>
          <Link href="/company">Company</Link>
          <Link href="/job-openings">Job Openings</Link>
          <a href="/press_releases.php">Press Releases</a>
          <Link href="/privacy">Privacy Policy</Link>
          <Link href="/return-policy">Warranty/Return Policy</Link>
          <Link href="/tos">Terms Of Service</Link>
        </div>
        <div className="footer-column col-xs-6 col-sm-3" style={{ padding: 15 }}>
          <h1>Connect</h1>
          <a href="//www.facebook.com/FitnessRepairParts" rel="noreferrer" className="footer_social_media" target="_blank"><img src="/images/icon_facebook.png" title="Facebook" alt="Facebook" /></a>
          <a href="//twitter.com/repairparts" className="footer_social_media" rel="noreferrer" target="_blank"><img src="/images/icon_twitter.png" title="Twitter" alt="Twitter" /></a>
          <Link href="/links" ><a className="footer_social_media"><img src="/images/icon_links.png" title="Links" alt="Links" /></a></Link>
        </div>
      </div>

      <div id="footer_copy">
        <p>Copyright 1995-{(new Date()).getFullYear()} Fitness Plus Equipment Services, Inc. All Rights Reserved.</p>
        <div id="security_checks">
          {/* I'm not crazy about this setup, but this is the way I was able to get it working. The seal and card icons are backwards, because of the float:right style hard coded into the icons... */}
          <div
            className="mcafee"
            dangerouslySetInnerHTML={{
              __html: '<img style=\'float:right;\' id="trustwaveSealImage" src="https://sealserver.trustwave.com/seal_image.php?customerId=3cf4c6ddf41b4b2d9c26a519698f699e&size=105x54&style=" border="0" style="cursor:pointer;" onclick="javascript:window.open(\'https://sealserver.trustwave.com/cert.php?customerId=3cf4c6ddf41b4b2d9c26a519698f699e&size=105x54&style=\', \'c_TW\', \'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720\'); return false;" oncontextmenu="javascript:alert(\'Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.\'); return false;" alt="This site is protected by Trustwave\'s Trusted Commerce program" title="This site is protected by Trustwave\'s Trusted Commerce program" />'
            }}
          />
        </div>
        <div>
          <Visa />
          <Mastercard />
          <Discover />
        </div>
      </div>
    </div>
  );
}

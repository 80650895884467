import React from 'react';

export const LoginPromptContext = React.createContext({ isOpen: false, openPrompt: () => {}, closePrompt: () => {} });

export function useLoginPrompt() {
  return React.useContext(LoginPromptContext);
}

interface Props {
  children: React.ReactElement;
}

export function LoginPromptProvider({ children }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  function openPrompt() {
    setIsOpen(true);
  }

  function closePrompt() {
    setIsOpen(false);
  }

  return (
    <LoginPromptContext.Provider value={{ isOpen, openPrompt, closePrompt }}>
      {children}
    </LoginPromptContext.Provider>
  );
}
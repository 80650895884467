import addNamespace from '../../addNamespace';

const GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST';
const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';


export default addNamespace({
  GET_LOCATION_REQUEST,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
}, 'locations');

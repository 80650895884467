import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import types from './types';

export function vendors(state = Immutable.List([]), action) {
  switch (action.type) {
    case types.GET_VENDORS_SUCCESS:
      return Immutable.fromJS(action.payload);
    default:
      return state;
  }
}

const initialTerm = Immutable.Map({
  term: '',
  definition: '',
  synonyms: [],
  notification: ''
})

export function term(state = initialTerm, action) {
  switch(action.type) {
    case types.FIND_TERM_SUCCESS:
    case types.GET_TERM_SUCCESS:
      return state.merge(action.payload);
    case types.FIND_TERM_FAILURE:
      return Immutable.Map({
        term: action.term,
        definition: '',
        synonyms: []
      });
    case types.SAVE_TERM_SUCCESS:
      return state.merge(action.payload).set('notification', 'created');
    case  'UPDATE_TERM_SUCCESS':
      return state.merge(action.payload).set('notification', 'updated');
    case types.RESET_TERM:
      return initialTerm;
    case types.DELETE_TERM_SUCCESS:
      return state.set('notification', 'was deleted.');
    case types.SAVE_SYNONYM_SUCCESS:
      return state.merge(action.payload).set('notification', 'added')
    case types.DETACH_SYNONYM_SUCCESS:
      return state.set('notification', 'deleted');
    case types.RESET_NOTIFICATION:
      return state.set('notification', '');
    default:
      return state;
  }
}

const initialTerms = Immutable.Map({
  loading: true,
  terms: []
})

export function terms(state = initialTerms, action) {
  switch (action.type) {
    case types.GET_TERMS_SUCCESS:
      return state.set('terms', Immutable.fromJS(action.payload)).set('loading', false);
    default:
      return state;
  }
}

export function similar_accounts(state = Immutable.List(), action) {
  switch (action.type) {
    case types.GET_SIMILAR_ACCOUNTS_SUCCESS:
      return Immutable.fromJS(action.payload);
    case types.CLEAR_SIMILAR_ACCOUNTS:
      return Immutable.List();
    default:
      return state;
  }
}

const initialSelectedAccounts = Immutable.Map({
  remove_account: Immutable.Map({}),
  merge_into_account: Immutable.Map({}),
  message: '',
  error: ''
});

function selected_accounts(state = initialSelectedAccounts, action) {
  switch (action.type) {
    case types.MERGE_ACCOUNTS_SUCCESS:
      return state.set('message', action.payload.message);
    case types.MERGE_ACCOUNTS_FAILURE:
      return state.set('error', action.payload.message);
    case types.GET_ACCOUNT_SUCCESS:
      return state.set(action.account_type, Immutable.fromJS(action.payload));
    case types.CLEAR_ACCOUNT:
      switch (action.payload) {
        case 'remove_account':
          return state.set('remove_account', Immutable.Map());
        case 'merge_into_account':
          return state.set('merge_into_account', Immutable.Map());
        default:
          return initialSelectedAccounts;
      }
    default:
      return state;
  }
}

const initialStates = Immutable.Map({
  loading: false,
  states: Immutable.List([])
})

export function states(state = initialStates, action) {
  switch (action.type) {
    case types.GET_STATES_REQUEST:
      return state.set('loading', true);
    case types.GET_STATES_SUCCESS:
      return state.set('loading', false).set('states', Immutable.fromJS(action.payload));
    default:
      return state;
  }
}

const initialExpandedShippingRates = {
  request: {},
  response: {},
  enabled_rates: [],
  packages: [],
  possible_surcharges: {},
  adjusted_rates: [],
  customer_rates: {}
}

export function expandedShippingRates(state = initialExpandedShippingRates, action) {
  switch (action.type) {
    case types.GET_EXPANDED_SHIPPING_RATES_SUCCESS:
      return action.payload;
    case types.CLEAR_EXPANDED_SHIPPING_RATES:
      return initialExpandedShippingRates;
    default:
      return state;
  }
}

const initialWhProducts = Immutable.fromJS({
  total: 0,
  per_page: '50',
  last_page: 1,
  from: 0,
  to: 0,
  data: [],
  loading: true
})

export function productsWithoutVendors(state = initialWhProducts, action) {
  switch (action.type) {
    case types.GET_PRODUCTS_WITHOUT_VENDORS_SUCCESS:
      return state.merge(Immutable.fromJS(action.payload)).set('loading', false);
    case types.GET_PRODUCTS_WITHOUT_VENDORS_FAILURE:
      return state.set('loading', false);
    default:
      return state;
  }
}

const initialShippingBillSummary = {
  loading: false,
  error: false,
  undercharged_orders: [],
  possible_delivery_failures: [],
  bill_watch: [],
  missing_orders: []
}

export function shippingBillSummary(state = initialShippingBillSummary, action) {
  switch (action.type) {
    case types.GET_SHIPPING_BILL_SUMMARY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export function marketplaceVendors(state = Immutable.List([]), action) {
  switch (action.type) {
    case types.GET_MARKETPLACE_VENDORS_SUCCESS:
      return Immutable.fromJS(action.payload);
    default:
      return state;
  }
}

const initialFiles = {
  total: 1,
  per_page: 50,
  current_page: 1,
  last_page: 1,
  from: 1,
  to: 1,
  data: []
}

export function files(state = initialFiles, action) {
  switch (action.type) {
    case types.GET_FILES_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

const initialCsv = {
  error: false,
  loading: false,
  fileLoaded: false
}
export function filesCsv(state = initialCsv, action) {
  switch (action.type) {
    case types.GET_FILES_CSV_REQUEST:
      return {...state, loading: true, fileLoaded: false}
    case types.GET_FILES_CSV_SUCCESS:
      return {...state, loading: false, fileLoaded: true}
    case types.GET_FILES_CSV_FAILURE:
      return {...state, error: true, loading: false}
    default:
      return state;
  }
}
const reducer = combineReducers({
  vendors, 
  term,
  terms,
  similar_accounts,
  selected_accounts,
  states,
  expandedShippingRates,
  productsWithoutVendors,
  shippingBillSummary,
  marketplaceVendors,
  files,
  filesCsv,
});

export default reducer;


import React from 'react';
import { useLoginPrompt } from '../../hooks/LoginPromptContext';
import SSOLoginPopup from '@developers/single-sign-on/components/Login/Popup';

export default function LoginPopup() {
  const { isOpen, closePrompt } = useLoginPrompt();

  return (
    <SSOLoginPopup
      open={isOpen}
      handleClose={closePrompt}
      forgotPassword="/forgotpassword.php"
      createAccount="/join.php"
    />
  );
}
import React from 'react';
import comm100 from './comm100';
import livehelperchat from './livehelperchat';

interface Props {
  chat: string;
}

function Chat(props: Props) {
  React.useEffect(() => {
    switch (props.chat) {
      case 'comm100':
        comm100();
        break;
      case 'livehelperchat':
        livehelperchat();
        break;
    }
  }, [props.chat]);

  if (props.chat === 'comm100') {
    return (
      <div id="comm_chat"><div id="comm100-button-d1070000-0000-0000-0000-008e0001655a"></div></div>
    );
  }

  return null;
}

export default Chat;

import addNamespace from '../addNamespace';

const CHANGE_STEP_REQUEST = 'CHANGE_STEP_REQUEST';
const CHANGE_STEP = 'CHANGE_STEP';
const CHANGE_STEP_FAILURE = 'CHANGE_STEP_FAILURE';
const SUBMIT_SHIPPING_REQUEST = 'SUBMIT_SHIPPING_REQUEST';
const SUBMIT_SHIPPING_SUCCESS = 'SUBMIT_SHIPPING_SUCCESS';
const SUBMIT_SHIPPING_FAILURE = 'SUBMIT_SHIPPING_FAILURE';
const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
const SUBMIT_BILLING_REQUEST = 'SUBMIT_BILLING_REQUEST';
const SUBMIT_BILLING_SUCCESS = 'SUBMIT_BILLING_SUCCESS';
const SUBMIT_BILLING_FAILURE = 'SUBMIT_BILLING_FAILURE';
const GET_CART_REQUEST = 'GET_CART_REQUEST';
const GET_CART_SUCESS = 'GET_CART_SUCCESS';
const GET_CART_FAILURE = 'GET_CART_FAILURE';
const UPDATE_SHIPPING_REQUEST = 'UPDATE_SHIPPING_REQUEST';
const UPDATE_SHIPPING_SUCCESS = 'UPDATE_SHIPPING_SUCCESS';
const UPDATE_SHIPPING_FAILURE = 'UPDATE_SHIPPING_FAILURE';
const UPDATE_CART_REQUEST = 'UPDATE_CART_REQUEST';
const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS';
const UPDATE_CART_FAILURE = 'UPDATE_CART_FAILURE';
const REMOVE_ITEM_REQUEST = 'REMOVE_ITEM_REQUEST';
const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
const REMOVE_ITEM_FAILURE = 'REMOVE_ITEM_FAILURE';
const SET_POLICY = 'SET_POLICY';
const TOUCH_POLICY = 'TOUCH_POLICY';
const PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST';
const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT';
const GET_RETURN_POLICY_REQUEST = 'GET_RETURN_POLICY_REQUEST';
const GET_RETURN_POLICY_SUCCESS = 'GET_RETURN_POLICY_SUCCESS';
const GET_RETURN_POLICY_FAILURE = 'GET_RETURN_POLICY_FAILURE';
const VALIDATE_ADDRESS_REQUEST = 'VALIDATE_ADDRESS_REQUEST';
const VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS';
const VALIDATE_ADDRESS_FAILURE = 'VALIDATE_ADDRESS_FAILURE';
const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
const GET_ADDRESSES_FAILURE = 'GET_ADDRESSES_FAILURE';
const GET_CARD_INFO_REQUEST = 'GET_CARD_INFO_REQUEST';
const GET_CARD_INFO_SUCCESS = 'GET_CARD_INFO_SUCCESS';
const GET_CARD_INFO_FAILURE = 'GET_CARD_INFO_FAILURE';
const ORDER_SEARCH_REQUEST = 'ORDER_SEARCH_REQUEST';
const ORDER_SEARCH_SUCCESS = 'ORDER_SEARCH_SUCCESS';
const ORDER_SEARCH_FAILURE = 'ORDER_SEARCH_FAILURE';
const ACCOUNT_SEARCH_REQUEST = 'ACCOUNT_SEARCH_REQUEST';
const ACCOUNT_SEARCH_SUCCESS = 'ACCOUNT_SEARCH_SUCCESS';
const ACCOUNT_SEARCH_FAILURE = 'ACCOUNT_SEARCH_FAILURE';
const SET_USER_REQUEST = 'SET_USER_REQUEST';
const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
const SET_USER_FAILURE = 'SET_USER_FAILURE';
const UPDATE_ORDER_TYPE = 'UPDATE_ORDER_TYPE';
const UPDATE_ACCOUNT_NUMBER = 'UPDATE_ACCOUNT_NUMBER';
const SET_PURCHASE_TAKEN_BY = 'SET_PURCHASE_TAKEN_BY';
const GET_ZIP_DATA_REQUEST = 'GET_ZIP_DATA_REQUEST';
const GET_ZIP_DATA_SUCCESS = 'GET_ZIP_DATA_SUCCESS';
const GET_ZIP_DATA_FAILURE = 'GET_ZIP_DATA_FAILURE';
const SET_ADDRESS_BLOCK = 'SET_ADDRESS_BLOCK';
const SET_SEND_CHECK = 'SET_SEND_CHECK';
const LOCK_STEP = 'LOCK_STEP';
const SET_PREVIOUS_ORDER_NUMBER = 'SET_PREVIOUS_ORDER_NUMBER';
const RESET_STEP = 'RESET_STEP';
const CLEAR_PENDING_REQUEST = 'CLEAR_PENDING_REQUEST';
const CLEAR_PENDING_SUCCESS = 'CLEAR_PENDING_SUCCESS';
const CLEAR_PENDING_FAILURE = 'CLEAR_PENDING_FAILURE';
const SET_WARRANTY_REQUEST = 'SET_WARRANTY_REQUEST';
const SET_WARRANTY_SUCCESS = 'SET_WARRANTY_SUCCESS';
const SET_WARRANTY_FAILURE = 'SET_WARRANTY_FAILURE';
const SET_PURCHASE_SITE_REQUEST = 'SET_PURCHASE_SITE_REQUEST';
const SET_PURCHASE_SITE_SUCCESS = 'SET_PURCHASE_SITE_SUCCESS';
const SET_PURCHASE_SITE_FAILURE = 'SET_PURCHASE_SITE_FAILURE';
const SET_SURCHARGE_REQUESTED = 'SET_SURCHARGE_REQUESTED';
const OVERWRITE_VALID_STEP = 'OVERWRITE_VALID_STEP';
const SET_CHECKOUT_LOADING = 'SET_CHECKOUT_LOADING';
const SET_ERROR_POPUP = 'SET_ERROR_POPUP';
const RESET_ERROR_POPUP = 'RESET_ERROR_POPUP';
const SET_AVS_POPUP = 'SET_AVS_POPUP';
const RESET_AVS_POPUP = 'RESET_AVS_POPUP';

export default addNamespace({
  CHANGE_STEP_REQUEST,
  CHANGE_STEP,
  CHANGE_STEP_FAILURE,
  SUBMIT_SHIPPING_REQUEST,
  SUBMIT_SHIPPING_SUCCESS,
  SUBMIT_SHIPPING_FAILURE,
  UPDATE_CREDIT_CARD,
  SUBMIT_BILLING_REQUEST,
  SUBMIT_BILLING_SUCCESS,
  SUBMIT_BILLING_FAILURE,
  GET_CART_REQUEST,
  GET_CART_SUCESS,
  GET_CART_FAILURE,
  UPDATE_SHIPPING_REQUEST,
  UPDATE_SHIPPING_SUCCESS,
  UPDATE_SHIPPING_FAILURE,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_FAILURE,
  REMOVE_ITEM_REQUEST,
  REMOVE_ITEM_SUCCESS,
  REMOVE_ITEM_FAILURE,
  SET_POLICY,
  TOUCH_POLICY,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  CLEAR_CHECKOUT,
  GET_RETURN_POLICY_REQUEST,
  GET_RETURN_POLICY_SUCCESS,
  GET_RETURN_POLICY_FAILURE,
  VALIDATE_ADDRESS_REQUEST,
  VALIDATE_ADDRESS_SUCCESS,
  VALIDATE_ADDRESS_FAILURE,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  GET_ADDRESSES_FAILURE,
  GET_CARD_INFO_REQUEST,
  GET_CARD_INFO_SUCCESS,
  GET_CARD_INFO_FAILURE,
  ORDER_SEARCH_REQUEST,
  ORDER_SEARCH_SUCCESS,
  ORDER_SEARCH_FAILURE,
  ACCOUNT_SEARCH_REQUEST,
  ACCOUNT_SEARCH_SUCCESS,
  ACCOUNT_SEARCH_FAILURE,
  UPDATE_ORDER_TYPE,
  UPDATE_ACCOUNT_NUMBER,
  SET_PURCHASE_TAKEN_BY,
  GET_ZIP_DATA_REQUEST,
  GET_ZIP_DATA_SUCCESS,
  GET_ZIP_DATA_FAILURE,
  SET_USER_REQUEST,
  SET_USER_SUCCESS,
  SET_USER_FAILURE,
  SET_ADDRESS_BLOCK,
  SET_SEND_CHECK,
  LOCK_STEP,
  SET_PREVIOUS_ORDER_NUMBER,
  RESET_STEP,
  CLEAR_PENDING_REQUEST,
  CLEAR_PENDING_SUCCESS,
  CLEAR_PENDING_FAILURE,
  SET_WARRANTY_REQUEST,
  SET_WARRANTY_SUCCESS,
  SET_WARRANTY_FAILURE,
  SET_PURCHASE_SITE_REQUEST,
  SET_PURCHASE_SITE_SUCCESS,
  SET_PURCHASE_SITE_FAILURE,
  SET_SURCHARGE_REQUESTED,
  OVERWRITE_VALID_STEP,
  SET_CHECKOUT_LOADING,
  SET_ERROR_POPUP,
  RESET_ERROR_POPUP,
  SET_AVS_POPUP,
  RESET_AVS_POPUP,
}, 'checkout');

import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import types from './types';


export function reducer(state = Immutable.Map({}), action) {
  switch (action.type) {
    case types.GET_LOCATION_SUCCESS:
      return Immutable.fromJS(action.payload);
    default:
      return state;
  }
}

export default reducer;

import Immutable from 'immutable';
import types from './types';

const reducer = (state = Immutable.List(), action) => {
  switch (action.type) {
    case types.GET_STATES_SUCCESS:
      return Immutable.fromJS(action.payload);
    default:
      return state;
  }
}

export default reducer;
